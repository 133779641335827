import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, Select, Switch, message, Space, Row, Col, Card, Typography, Spin } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, EyeOutlined, SearchOutlined, TruckOutlined } from '@ant-design/icons';
import { supabase } from '../../utils/supabaseClient';

const { Title } = Typography;
const { Option } = Select;

interface XbinTruck {
  id: string;
  company: string;
  driver_name: string;
  driver_inducted: boolean;
  date_inducted: string | null;
  contact_number: string;
  truck_type: string;
  tare: number;
  max_gross: number;
  registration: string;
  date_added: string;
}

const XbinTruckComponent: React.FC = () => {
  const [trucks, setTrucks] = useState<XbinTruck[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingTruck, setEditingTruck] = useState<XbinTruck | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [form] = Form.useForm();

  useEffect(() => {
    fetchTrucks();
  }, []);

  const fetchTrucks = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('xbin_trucks')
        .select('*')
        .order('company', { ascending: true });

      if (error) throw error;
      setTrucks(data || []);
    } catch (error) {
      console.error('Error fetching trucks:', error);
      message.error('Failed to fetch trucks');
    } finally {
      setLoading(false);
    }
  };

  const handleSaveTruck = async (values: any) => {
    try {
      const formattedValues = {
        ...values,
        date_inducted: values.driver_inducted ? new Date().toISOString() : null,
        date_added: new Date().toISOString(),
      };

      if (editingTruck) {
        const { error } = await supabase
          .from('xbin_trucks')
          .update(formattedValues)
          .eq('id', editingTruck.id);

        if (error) throw error;
        message.success('Truck updated successfully');
      } else {
        const { error } = await supabase
          .from('xbin_trucks')
          .insert(formattedValues);

        if (error) throw error;
        message.success('Truck added successfully');
      }

      setModalVisible(false);
      form.resetFields();
      fetchTrucks();
    } catch (error) {
      console.error('Error saving truck:', error);
      message.error('Failed to save truck');
    }
  };

  const handleDeleteTruck = async (id: string) => {
    try {
      const { error } = await supabase
        .from('xbin_trucks')
        .delete()
        .eq('id', id);

      if (error) throw error;
      message.success('Truck deleted successfully');
      fetchTrucks();
    } catch (error) {
      console.error('Error deleting truck:', error);
      message.error('Failed to delete truck');
    }
  };

  const columns = [
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: 'Driver Name',
      dataIndex: 'driver_name',
      key: 'driver_name',
    },
    {
      title: 'Truck Type',
      dataIndex: 'truck_type',
      key: 'truck_type',
    },
    {
      title: 'Registration',
      dataIndex: 'registration',
      key: 'registration',
    },
    {
      title: 'Inducted',
      dataIndex: 'driver_inducted',
      key: 'driver_inducted',
      render: (inducted: boolean) => (inducted ? 'Yes' : 'No'),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: XbinTruck) => (
        <Space size="small">
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setEditingTruck(record);
              form.setFieldsValue({
                ...record,
              });
              setModalVisible(true);
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteTruck(record.id)}
            danger
          />
          <Button
            icon={<EyeOutlined />}
            onClick={() => {
              // Implement view more details functionality
            }}
          />
        </Space>
      ),
    },
  ];

  const filteredTrucks = trucks.filter(truck =>
    truck.registration.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="xbin-truck-container" style={{ padding: '24px' }}>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Spin size="large" />
        </div>
      ) : (
        <Card
          title={<Title level={3}><TruckOutlined /> xBin Trucks</Title>}
          extra={
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setEditingTruck(null);
                form.resetFields();
                setModalVisible(true);
              }}
            >
              Add New Truck
            </Button>
          }
        >
          <div style={{ marginBottom: 16 }}>
            <Input
              placeholder="Search by registration"
              prefix={<SearchOutlined />}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ width: '300px' }}
            />
          </div>
          <Table
            columns={columns}
            dataSource={filteredTrucks}
            rowKey="id"
          />
        </Card>
      )}
      <Modal
        title={
          <Title level={3}>
            <TruckOutlined /> {editingTruck ? "Edit Truck" : "Add New Truck"}
          </Title>
        }
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setEditingTruck(null);
          form.resetFields();
        }}
        footer={null}
        width={720}
      >
        <Card>
          <Form form={form} onFinish={handleSaveTruck} layout="vertical">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="company"
                  label="Company"
                  rules={[{ required: true, message: 'Please input the company name!' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="driver_name"
                  label="Driver Name"
                  rules={[{ required: true, message: 'Please input the driver name!' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="contact_number"
                label="Contact Number"
                rules={[{ required: true, message: 'Please input the contact number!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="driver_inducted"
                label="Driver Inducted"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="truck_type"
                label="Truck Type"
                rules={[{ required: true, message: 'Please select the truck type!' }]}
              >
                <Select>
                  <Option value="Truck and Dog">Truck and Dog</Option>
                  <Option value="Semi">Semi</Option>
                  <Option value="10 Wheeler">10 Wheeler</Option>
                  <Option value="8 Wheeler">8 Wheeler</Option>
                  <Option value="6 Wheeler">6 Wheeler</Option>
                  <Option value="Other">Other</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="registration"
                label="Registration"
                rules={[{ required: true, message: 'Please input the registration!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="tare"
                label="Tare"
                rules={[{ required: true, message: 'Please input the tare weight!' }]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="max_gross"
                label="Max Gross"
                rules={[{ required: true, message: 'Please input the max gross weight!' }]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
              <Button type="primary" htmlType="submit">
                {editingTruck ? 'Update Truck' : 'Add Truck'}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};

export default XbinTruckComponent;