import React from 'react';
import { Modal, Typography, Row, Col, Card, Divider, Tag, Statistic } from 'antd';
import { FileTextOutlined, TruckOutlined, EnvironmentOutlined, ShoppingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const { Title, Text } = Typography;

interface DocketModalProps {
  visible: boolean;
  onClose: () => void;
  docket: any;
  customerName: string;
  productDetails: { name: string; type: string | null; product_code?: string; lot_number?: string } | null;
}

const DocketModal: React.FC<DocketModalProps> = ({ visible, onClose, docket, customerName, productDetails }) => {
  if (!docket) return null;

  const renderDocketContent = () => (
    <div className="docket-content" style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px', lineHeight: 1.6 }}>
      <Card>
        <Row gutter={[16, 16]} align="middle">
          <Col span={12}>
            <Title level={4}>Docket #{docket.docket_number}</Title>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Text>{dayjs(docket.created_at).format('DD/MM/YYYY HH:mm')}</Text>
          </Col>
        </Row>
      </Card>

      <Divider orientation="left">Customer Information</Divider>
      <Card>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Text strong>Customer:</Text> {customerName}
          </Col>
          <Col span={12}>
            <Text strong>Customer Number:</Text> {docket.customer_number || 'N/A'}
          </Col>
          <Col span={24}>
            <Text strong><EnvironmentOutlined /> Delivery Address:</Text> {docket.delivery_address}
          </Col>
        </Row>
      </Card>

      <Divider orientation="left">Order Details</Divider>
      <Card>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Text strong>Order Number:</Text> {docket.order_number || 'N/A'}
          </Col>
          <Col span={12}>
            <Text strong>Job Number:</Text> {docket.job_number || 'N/A'}
          </Col>
          <Col span={24}>
            <Text strong><ShoppingOutlined /> Product:</Text> {productDetails?.name} {productDetails?.type ? `(${productDetails.type})` : ''}
          </Col>
          <Col span={12}>
            <Text strong>Product Code:</Text> {productDetails?.product_code || 'N/A'}
          </Col>
          <Col span={12}>
            <Text strong>Lot Number:</Text> {productDetails?.lot_number || 'N/A'}
          </Col>
        </Row>
      </Card>

      <Divider orientation="left">Transport Details</Divider>
      <Card>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Text strong><TruckOutlined /> Fleet Number:</Text> <Tag color="blue">{docket.fleet_number || 'N/A'}</Tag>
          </Col>
          <Col span={12}>
            <Text strong>Registration:</Text> {docket.truck_rego || 'N/A'}
          </Col>
        </Row>
      </Card>

      <Divider orientation="left">Weight Information</Divider>
      <Card>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Statistic title="Gross Weight" value={docket.gross_weight?.toFixed(2) || 'N/A'} suffix="t" />
          </Col>
          <Col span={8}>
            <Statistic title="Tare Weight" value={docket.tare_weight?.toFixed(2) || 'N/A'} suffix="t" />
          </Col>
          <Col span={8}>
            <Statistic title="Net Weight" value={docket.net_weight?.toFixed(2) || 'N/A'} suffix="t" />
          </Col>
        </Row>
      </Card>

      <Divider orientation="left">Progressive Total</Divider>
      <Card>
        <Row>
          <Col span={24}>
            <Statistic title="Progressive Total" value={docket.progressive?.toFixed(2) || 'N/A'} suffix="t" />
          </Col>
        </Row>
      </Card>
    </div>
  );

  return (
    <Modal
      title={<Title level={4}><FileTextOutlined /> Docket Details</Title>}
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={700}
    >
      {renderDocketContent()}
    </Modal>
  );
};

export default DocketModal;