import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Menu, Input, Button, Card, Typography, Spin, message, InputNumber, Progress, Row, Col, Tag, Select } from 'antd';
import { SearchOutlined, PrinterOutlined, FileTextOutlined, TruckOutlined, UnorderedListOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { supabase } from '../utils/supabaseClient';
import dayjs from 'dayjs';
import './Dockets.css';
import ReactDOMServer from 'react-dom/server';
import { PrintTemplate } from './PrintTemplate';
import XbinDocket from './dockets/XbinDocket';
import XbinTruck from './dockets/XbinTruck';
import DocketList from './dockets/DocketList';

const { Title, Text } = Typography;
const { Option } = Select;

interface JobData {
  id: string;
  job_number: number;
  customer_id: string;
  delivery_address: string;
  product: string;
  quantity: string;
  trucks_allocated: string[];
  progressive: string;
  order_number: string;
  plant: { name: string }[];
  truck_type: string;
}

interface TruckData {
  id: string;
  fleet_number: string;
  body_tare: number;
  trailer_tare: number;
  tare: number;
  truck_rego: string;
}

interface CustomerData {
  id: string;
  customer_name: string;
  customer_number: string;
}

interface ProductData {
  id: string;
  name: string;
  type: string | null;
  product_code: string;
  lot_number: string;
}

interface JobDetails {
  product: string;
  quantity_ordered: number;
  progressive: number;
}

interface TruckProgress {
  fleet_number: string;
  progressive: number;
}

const Dockets: React.FC = () => {
  const [currentPage, setCurrentPage] = useState('dockets');
  const [searchValue, setSearchValue] = useState('');
  const [jobData, setJobData] = useState<JobData | null>(null);
  const [truckData, setTruckData] = useState<TruckData | null>(null);
  const [customerData, setCustomerData] = useState<CustomerData | null>(null);
  const [productData, setProductData] = useState<ProductData | null>(null);
  const [grossWeight, setGrossWeight] = useState<number | null>(null);
  const [jobDetails, setJobDetails] = useState<JobDetails | null>(null);
  const [allocatedTrucks, setAllocatedTrucks] = useState<TruckProgress[]>([]);
  const [loading, setLoading] = useState(false);

  const searchInputRef = useRef<InputRef>(null);
  const grossWeightInputRef = useRef<HTMLInputElement>(null);

  const [isSplitLoad, setIsSplitLoad] = useState(false);
  const [splitLoadPart, setSplitLoadPart] = useState<'truck' | 'trailer' | null>(null);

  const handleGrossWeightChange = (value: number | null) => {
    setGrossWeight(value);
  };

  const handleGrossWeightPressEnter = () => {
    handleSaveAndPrint();
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const fetchJobDetails = useCallback(async (jobId: string) => {
    try {
      const { data: jobData, error: jobError } = await supabase
        .from('jobs')
        .select('product, quantity, progressive')
        .eq('id', jobId)
        .single();

      if (jobError) throw jobError;

      const today = dayjs().format('YYYY-MM-DD');

      const { data: todayDockets, error: docketsError } = await supabase
        .from('pulse_dockets')
        .select('quantity, fleet_number')
        .eq('job_id', jobId)
        .gte('created_at', today)
        .lte('created_at', today + 'T23:59:59');

      if (docketsError) throw docketsError;

      setJobDetails({
        product: jobData.product,
        quantity_ordered: parseFloat(jobData.quantity),
        progressive: jobData.progressive
      });

      const truckProgress = todayDockets.reduce((acc, docket) => {
        if (!acc[docket.fleet_number]) {
          acc[docket.fleet_number] = 0;
        }
        acc[docket.fleet_number] += (docket.quantity || 0);
        return acc;
      }, {} as Record<string, number>);

      setAllocatedTrucks(Object.entries(truckProgress).map(([fleet_number, progressive]) => ({
        fleet_number,
        progressive
      })));

    } catch (error) {
      console.error('Error fetching job details:', error);
      message.error('Failed to fetch job details');
    }
  }, []);

  useEffect(() => {
    if (jobData) {
      fetchJobDetails(jobData.id);
    }
  }, [jobData, fetchJobDetails]);

  const printDocket = async (docketData: any) => {
  const printContent = ReactDOMServer.renderToString(
    <PrintTemplate
      docketData={{
          docket_number: docketData.docket_number,
          plant: jobData?.plant?.[0]?.name,
          customer: customerData?.customer_name || '',
          customer_number: customerData?.customer_number,
          delivery_address: docketData.delivery_address,
          order_number: jobData?.order_number,
          product_name: productData?.name || '',
          product_type: productData?.type ?? undefined,
          product_code: productData?.product_code,
          lot_number: productData?.lot_number,
          job_number: jobData?.job_number?.toString(),
          fleet_number: docketData.fleet_number,
          progressive: docketData.progressive,
          gross_weight: docketData.gross_weight,
          tare_weight: docketData.tare_weight,
          net_weight: docketData.net_weight
        }}
      type="docket"
    />
  );

  if (window.opener && window.opener.electronAPI) {
    try {
      const result = await window.opener.electronAPI.printDocket(printContent);
      if (result.success) {
        message.success('Docket printed successfully');
      } else {
        throw new Error(result.error);
      }
    } catch (error) {
      console.error('Error printing docket:', error);
      message.error('Failed to print docket');
    }
  } else {
    console.error('Electron API not available');
    message.error('Unable to print docket');
  }
};

  const handleSearch = async () => {
  setLoading(true);
  try {
    const today = dayjs().format('YYYY-MM-DD');
    const { data: jobData, error: jobError } = await supabase
      .from('jobs')
      .select(`
        id, job_number, customer_id, delivery_address, product, quantity, 
        trucks_allocated, progressive, order_number, suburb, truck_type,
        plant(name)
      `)
      .eq('delivery_date', today)
      .contains('trucks_allocated', [searchValue])
      .single();

    if (jobError) throw jobError;

    const { data: truckData, error: truckError } = await supabase
      .from('trucks')
      .select('id, fleet_number, body_tare, trailer_tare, tare, truck_rego')
      .eq('fleet_number', searchValue)
      .single();

    if (truckError) throw truckError;

    const { data: customerData, error: customerError } = await supabase
      .from('customers')
      .select('id, customer_name, customer_number')
      .eq('id', jobData.customer_id)
      .single();

    if (customerError) throw customerError;

    const [productName, productType] = jobData.product.split(' (');
    const cleanProductType = productType ? productType.slice(0, -1) : null;

    const { data: productsData, error: productsError } = await supabase
      .from('products')
      .select('id, name, type, product_code, lot_number')
      .eq('name', productName);

    if (productsError) {
      throw new Error('Products not found');
    }

    let selectedProduct = cleanProductType
      ? productsData.find(p => p.type === cleanProductType)
      : productsData[0];

    if (!selectedProduct) {
      throw new Error('No matching product found');
    }

    setJobData({
      ...jobData,
      delivery_address: `${jobData.delivery_address}${jobData.suburb ? ', ' + jobData.suburb : ''}`,
      truck_type: jobData.truck_type || 'Body Truck', // Provide a default value if truck_type is not present
    });
    setTruckData(truckData);
    setCustomerData(customerData);
    setProductData(selectedProduct);
    setGrossWeight(null);
    setIsSplitLoad(false);
    setSplitLoadPart(null);

    setTimeout(() => {
      grossWeightInputRef.current?.focus();
    }, 0);
  } catch (error) {
    console.error('Error searching for job or truck:', error);
    message.error('No job or truck found for this number for today');
    setJobData(null);
    setTruckData(null);
    setCustomerData(null);
    setProductData(null);
    setJobDetails(null);
    setAllocatedTrucks([]);
  } finally {
    setLoading(false);
  }
};

  const calculateNetWeight = () => {
    if (grossWeight !== null && truckData && jobData) {
      let currentTare: number;

      if (isSplitLoad) {
        currentTare = splitLoadPart === 'truck' ? truckData.body_tare : (truckData.trailer_tare || 0);
      } else {
        currentTare = jobData.truck_type === 'Body Truck' ? truckData.body_tare : truckData.tare;
      }

      return grossWeight - currentTare;
    }
    return null;
  };

  const handleSaveAndPrint = async () => {
    if (!jobData || !truckData || grossWeight === null || !productData) {
      message.error('Please fill in all required fields');
      return;
    }

    const netWeight = calculateNetWeight();

    if (netWeight === null) {
      message.error('Unable to calculate net weight');
      return;
    }

    try {
      const nextDocketNumber = await getNextDocketNumber();

      if (nextDocketNumber === null) {
        throw new Error('Failed to generate docket number');
      }

      const newProgressive = (jobDetails?.progressive || 0) + netWeight;

      const { data, error } = await supabase
        .from('pulse_dockets')
        .insert({
          job_id: jobData.id,
          truck_id: truckData.id,
          fleet_number: truckData.fleet_number,
          docket_number: nextDocketNumber,
          customer_id: jobData.customer_id,
          delivery_address: jobData.delivery_address,
          product_id: productData.id,
          quantity: netWeight,
          gross_weight: grossWeight,
          tare_weight: isSplitLoad 
            ? (splitLoadPart === 'truck' ? truckData.body_tare : truckData.trailer_tare)
            : (jobData.truck_type === 'Body Truck' ? truckData.body_tare : truckData.tare),
          net_weight: netWeight,
          progressive: newProgressive,
          is_split_load: isSplitLoad,
          split_load_part: splitLoadPart
        })
        .select()
        .single();

      if (error) throw error;

      const { error: jobUpdateError } = await supabase
        .from('jobs')
        .update({ progressive: newProgressive })
        .eq('id', jobData.id);

      if (jobUpdateError) throw jobUpdateError;

      setJobDetails(prev => prev ? {...prev, progressive: newProgressive} : null);

      message.success('Docket saved successfully');
      printDocket(data);

      resetForm();
    } catch (error) {
      console.error('Error saving docket:', error);
      message.error('Failed to save docket');
    }
  };

  const getNextDocketNumber = async (): Promise<number | null> => {
    const { data, error } = await supabase.rpc('get_next_pulse_docket_number');
    
    if (error) {
      console.error('Error getting next docket number:', error);
      return null;
    }
    
    return data;
  };
    
  const resetForm = () => {
    setSearchValue('');
    setJobData(null);
    setTruckData(null);
    setCustomerData(null);
    setProductData(null);
    setGrossWeight(null);
    setJobDetails(null);
    setAllocatedTrucks([]);
    searchInputRef.current?.focus();
  };

  const renderDocketContent = () => (
    <div className="docket-form">
      {loading && <Spin size="large" />}

      {jobData && truckData && customerData && productData && (
        <Card title="Delivery Docket" className="docket-card">
          <div className="docket-content">
            <Text strong>Job Number:</Text>
            <Text>{jobData.job_number}</Text>
            <Text strong>Customer:</Text>
            <Text>{customerData.customer_name}</Text>
            <Text strong>Delivery Address:</Text>
            <Text>{jobData.delivery_address}</Text>
            <Text strong>Product:</Text>
            <Text>{productData.name} {productData.type ? `(${productData.type})` : ''}</Text>
            <Text strong>Product Code:</Text>
            <Text>{productData.product_code}</Text>
            <Text strong>Lot Number:</Text>
            <Text>{productData.lot_number}</Text>
            <Text strong>Truck:</Text>
            <Text>{truckData.fleet_number}</Text>
            <Text strong>Body Tare Weight:</Text>
            <Text>{truckData.body_tare !== null ? truckData.body_tare.toFixed(2) : 'N/A'} t</Text>
            <Text strong>Trailer Tare Weight:</Text>
            <Text>{truckData.trailer_tare !== null ? truckData.trailer_tare.toFixed(2) : 'N/A'} t</Text>
            <Text strong>Combined Tare Weight:</Text>
            <Text>{truckData.tare.toFixed(2)} t</Text>
            <Text strong>Split Load:</Text>
            <Select
              value={isSplitLoad}
              onChange={(value: boolean) => setIsSplitLoad(value)}
              style={{ width: 100 }}
            >
              <Option value={false}>No</Option>
              <Option value={true}>Yes</Option>
            </Select>
            {isSplitLoad && (
              <>
                <Text strong>Split Load Part:</Text>
                <Select
                  value={splitLoadPart}
                  onChange={(value: 'truck' | 'trailer' | null) => setSplitLoadPart(value)}
                  style={{ width: 100 }}
                >
                  <Option value="truck">Truck</Option>
                  <Option value="trailer">Trailer</Option>
                </Select>
              </>
            )}
            <Text strong>Gross Weight:</Text>
            <InputNumber
              ref={grossWeightInputRef}
              value={grossWeight}
              onChange={handleGrossWeightChange}
              onPressEnter={handleGrossWeightPressEnter}
              min={0}
              step={0.01}
              precision={2}
              style={{ width: 200 }}
            />
            <Text strong>Net Weight:</Text>
            <Text>{calculateNetWeight()?.toFixed(2) || 'N/A'} t</Text>
          </div>
          <Button 
            type="primary" 
            icon={<PrinterOutlined />} 
            onClick={handleSaveAndPrint} 
            style={{ marginTop: 16 }}
          >
            Save and Print
          </Button>
        </Card>
      )}
    </div>
  );


  const renderJobDetails = () => {
    if (!jobDetails) return null;

    const progressPercentage = jobDetails.progressive && jobDetails.quantity_ordered
      ? (jobDetails.progressive / jobDetails.quantity_ordered) * 100
      : 0;

    return (
      <Card title="Job Details" className="job-details-card">
        <Row gutter={[16, 16]} align="middle">
          <Col span={12}>
            <Text strong>Product:</Text>
          </Col>
          <Col span={12}>
            <Text>{jobDetails.product}</Text>
          </Col>
          <Col span={12}>
            <Text strong>Quantity Ordered:</Text>
          </Col>
          <Col span={12}>
            <Text>{jobDetails.quantity_ordered?.toFixed(2) ?? 'N/A'} t</Text>
          </Col>
          <Col span={12}>
            <Text strong>Progressive Total:</Text>
          </Col>
          <Col span={12}>
            <Text>{jobDetails.progressive?.toFixed(2) ?? 'N/A'} t</Text>
          </Col>
          <Col span={24}>
            <Progress 
              percent={Math.min(progressPercentage, 100)} 
              status={progressPercentage >= 100 ? "success" : "active"}
              format={(percent) => `${percent?.toFixed(2) ?? 0}%`}
            />
          </Col>
          <Col span={24}>
            <Text strong>Allocated Trucks:</Text>
            <div className="allocated-trucks-list">
              {allocatedTrucks.map(item => (
                <div key={item.fleet_number} className="allocated-truck-item">
                  <Tag color="blue">{item.fleet_number}</Tag>
                  <Text>{item.progressive.toFixed(2)} t</Text>
                  <Progress 
                    percent={Math.min((item.progressive / jobDetails.quantity_ordered) * 100, 100)}
                    size="small"
                    showInfo={false}
                  />
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Card>
    );
  };

  const renderContent = () => {
    switch (currentPage) {
      case 'dockets':
        return (
          <Row gutter={24}>
            <Col span={12}>{renderDocketContent()}</Col>
            <Col span={12}>{renderJobDetails()}</Col>
          </Row>
        );
      case 'blank-docket':
        return <XbinDocket />;
      case 'xbin-truck':
        return <XbinTruck />;
      case 'docket-list':
        return <DocketList />;
      default:
        return <div>Select a page from the menu</div>;
    }
  };

  return (
    <div className="standalone-dockets-container">
      <div className="pulse-dockets-title">
        <Title level={3}>Pulse Dockets</Title>
      </div>
      <div className="dockets-content">
        <Menu
          mode="horizontal"
          selectedKeys={[currentPage]}
          onClick={({ key }) => setCurrentPage(key as string)}
        >
          <Menu.Item key="dockets" icon={<FileTextOutlined />}>Dockets</Menu.Item>
          <Menu.Item key="blank-docket" icon={<FileTextOutlined />}>xBin Docket</Menu.Item>
          <Menu.Item key="xbin-truck" icon={<TruckOutlined />}>xBin Truck</Menu.Item>
          <Menu.Item key="docket-list" icon={<UnorderedListOutlined />}>Docket List</Menu.Item>
        </Menu>
        <div className="dockets-main-content">
          {currentPage === 'dockets' && (
            <div className="search-container">
              <Input
                ref={searchInputRef}
                placeholder="Enter truck number"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyPress={handleKeyPress}
                style={{ width: 200 }}
              />
              <Button type="primary" icon={<SearchOutlined />} onClick={handleSearch}>
                Search
              </Button>
            </div>
          )}
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default Dockets;