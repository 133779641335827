import React, { useState, useEffect } from 'react';
import { Drawer, Typography, Table, Spin, Card, Row, Col, Tag, Alert } from 'antd';
import { supabase } from '../utils/supabaseClient';
import { TruckOutlined, FileTextOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const { Title, Text } = Typography;

interface DetailsDrawerProps {
  visible: boolean;
  onClose: () => void;
  jobId: string | null;
}

interface JobData {
  id: string;
  job_number: number;
  customer_id: string;
  delivery_address: string;
  product: string;
  quantity: string;
  status: string;
  delivery_date: string;
  progressive: string;
  suburb: string;
  uhf_channel: string;
  site_contact_name: string;
  contact_number: string;
  notes: string;
  truck_type: string;
}

interface DocketData {
  id: string;
  job_id: string;
  docket_number: number;
  quantity: number;
  gross_weight: number;
  tare_weight: number;
  net_weight: number;
  fleet_number: string;
  created_at: string;
}

const DetailsDrawer: React.FC<DetailsDrawerProps> = ({ visible, onClose, jobId }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [jobData, setJobData] = useState<JobData | null>(null);
  const [dockets, setDockets] = useState<DocketData[]>([]);
  const [customerName, setCustomerName] = useState<string>('');

  const getTruckColor = (type: string): string => {
  switch (type) {
    case 'Truck and Dog':
      return 'blue';
    case 'Body Truck':
      return 'green';
    case 'Semi':
      return 'orange';
    case '8 Wheeler':
      return 'purple';
    case '10 Wheeler':
      return 'red';
    case 'Side Tipper':
      return 'cyan';
    default:
      return 'default';
  }
};

  useEffect(() => {
    if (visible && jobId) {
      fetchJobDetails(jobId);
    }
  }, [visible, jobId]);

  const fetchJobDetails = async (id: string) => {
    setLoading(true);
    setError(null);
    try {
      // Fetch job details
      const { data: job, error: jobError } = await supabase
        .from('jobs')
        .select('*, suburb, uhf_channel, site_contact_name, contact_number, notes')
        .eq('id', id)
        .single();

      if (jobError) throw jobError;

      setJobData(job);

      // Fetch customer name
      if (job.customer_id) {
        const { data: customerData, error: customerError } = await supabase
          .from('customers')
          .select('customer_name')
          .eq('id', job.customer_id)
          .single();

        if (customerError) throw customerError;
        setCustomerName(customerData.customer_name);
      }

      // Fetch associated dockets
      const { data: docketsData, error: docketsError } = await supabase
        .from('pulse_dockets')
        .select('*')
        .eq('job_id', id);

      if (docketsError) throw docketsError;

      setDockets(docketsData || []);

    } catch (error) {
      console.error('Error fetching job details:', error);
      setError(error instanceof Error ? error.message : 'An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  const renderJobDetails = () => {
  if (!jobData) return null;

  return (
    <Card title={<Title level={4}><FileTextOutlined /> Job Details</Title>} style={{ marginBottom: 20 }}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Text strong>Job Number:</Text>
          <Text> {jobData.job_number}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Customer:</Text>
          <Text> {customerName}</Text>
        </Col>
        <Col span={24}>
          <Text strong>Delivery Address:</Text>
          <Text> {`${jobData.delivery_address}, ${jobData.suburb}`}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Product:</Text>
          <Text> {jobData.product}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Quantity:</Text>
          <Text> {jobData.quantity}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Progressive:</Text>
          <Text> {Number(jobData.progressive).toFixed(2)}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Status:</Text>
          <Text> {jobData.status}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Delivery Date:</Text>
          <Text> {dayjs(jobData.delivery_date).format('DD/MM/YYYY')}</Text>
        </Col>
        <Col span={12}>
          <Text strong>UHF Channel:</Text>
          <Text> {jobData.uhf_channel || 'N/A'}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Site Contact Name:</Text>
          <Text> {jobData.site_contact_name || 'N/A'}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Contact Number:</Text>
          <Text> {jobData.contact_number || 'N/A'}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Truck Type:</Text>
          {jobData.truck_type ? (
            <Tag color={getTruckColor(jobData.truck_type)} style={{ marginLeft: 8 }}>
              {jobData.truck_type}
            </Tag>
          ) : (
            <Text> N/A</Text>
          )}
        </Col>
        <Col span={24}>
          <Text strong>Notes:</Text>
          <Text> {jobData.notes || 'N/A'}</Text>
        </Col>
      </Row>
    </Card>
  );
};

  const renderDockets = () => {
    if (dockets.length === 0) return <Alert message="No dockets available for this job" type="info" showIcon />;

    const columns = [
      { 
        title: 'Docket Number', 
        dataIndex: 'docket_number', 
        key: 'docket_number',
        render: (text: number) => <Tag color="blue">#{text}</Tag>
      },
      { 
        title: 'Date', 
        dataIndex: 'created_at', 
        key: 'created_at',
        render: (text: string) => dayjs(text).format('DD/MM/YYYY HH:mm')
      },
      { title: 'Quantity', dataIndex: 'quantity', key: 'quantity', render: (text: number) => `${text.toFixed(2)} t` },
      { title: 'Net Weight', dataIndex: 'net_weight', key: 'net_weight', render: (text: number) => `${text.toFixed(2)} t` },
      { title: 'Truck', dataIndex: 'fleet_number', key: 'fleet_number' },
    ];

    return (
      <Card title={<Title level={4}><TruckOutlined /> Dockets</Title>} style={{ marginTop: 20 }}>
        <Table 
          dataSource={dockets} 
          columns={columns} 
          rowKey="id"
          pagination={false}
          size="small"
        />
      </Card>
    );
  };

  return (
    <Drawer
      title={<Title level={3}><FileTextOutlined /> Job Details</Title>}
      placement="right"
      onClose={onClose}
      open={visible}
      width={720}
      styles={{ body: { paddingBottom: 80 } }}
    >
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Spin size="large" />
        </div>
      ) : error ? (
        <Alert message="Error" description={error} type="error" showIcon />
      ) : (
        <>
          {renderJobDetails()}
          {renderDockets()}
        </>
      )}
    </Drawer>
  );
};

export default DetailsDrawer;