import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Table, Button, Modal, Form, Input, DatePicker, TimePicker, Tooltip, message, Select, Tag, Space, Typography, Row, Col, Popconfirm, InputNumber } from 'antd';
import { PlusOutlined, CalendarOutlined, DeleteTwoTone, EditTwoTone, SearchOutlined, EyeOutlined } from '@ant-design/icons';
import { supabase } from '../utils/supabaseClient';
import dayjs from 'dayjs';
import type { ColumnsType } from 'antd/es/table';
import DetailsDrawer from './DetailsDrawer';
import { useAuth } from '../contexts/AuthContext';

const { Title } = Typography;
const { Option } = Select;

const statusOptions = [
  { value: 'entered', label: 'Entered' },
  { value: 'started', label: 'Started' },
  { value: 'completed', label: 'Completed' },
  { value: 'on-hold', label: 'On Hold' },
  { value: 'cancelled', label: 'Cancelled' },
  { value: 'moved', label: 'Moved' },
];

interface Job {
  id: string;
  job_number: number;
  customer_name: string;
  customer_id: string;
  delivery_address: string;
  product: string;
  quantity: string;
  trucks_allocated: string[] | string;
  status: string;
  delivery_date: string;
  delivery_time: string;
  contact_number: string;
  site_contact_name: string;
  uhf_channel: string;
  notes: string;
  progressive: string;
  suburb: string;
  truck_type: string;
}

interface Truck {
  id: string;
  fleet_number: string;
  type: string;
  status: string;
  body_tare: number;
  trailer_tare: number;
  tare: number;
}

interface Customer {
  id: string;
  customer_name: string;
  order_count: number;
}

interface Product {
  id: string;
  name: string;
  type?: string;
}

interface OrderCount {
  customer_id: string;
  order_count: number;
}

const DailyPlanner: React.FC = () => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [trucks, setTrucks] = useState<Truck[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingJob, setEditingJob] = useState<Job | null>(null);
  const [nextJobNumber, setNextJobNumber] = useState(1000);
  const [filterDate, setFilterDate] = useState<dayjs.Dayjs>(dayjs());
  const [form] = Form.useForm();
  const [todayJobsCount, setTodayJobsCount] = useState(0);
  const [tomorrowJobsCount, setTomorrowJobsCount] = useState(0);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState<string | null>(null);
  const [userLocationId, setUserLocationId] = useState<string | null>(null);
  const [selectedLocationId, setSelectedLocationId] = useState<string | null>(null);
  const [locations, setLocations] = useState<{ id: string; name: string }[]>([]);
  const { user } = useAuth();
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const fetchJobs = useCallback(async () => {
    setLoading(true);
    try {
      let query = supabase
        .from('jobs')
        .select(`
          *,
          customers:customer_id (id, customer_name)
        `)
        .order('delivery_date', { ascending: true });

      if (selectedLocationId) {
        query = query.eq('location_id', selectedLocationId);
      }

      const { data, error } = await query;

      if (error) throw error;

      const jobsWithCustomerNames = data?.map(job => ({
        ...job,
        customer_name: job.customers?.customer_name
      })) || [];

      setJobs(jobsWithCustomerNames);
    } catch (error) {
      console.error('Error fetching jobs:', error);
      message.error('Error fetching jobs: ' + (error as Error).message);
    } finally {
      setLoading(false);
    }
  }, [selectedLocationId]);

  useEffect(() => {
    fetchLocations();
    if (user) {
      fetchUserLocation(user.id);
    }
  }, [user]);

  useEffect(() => {
  const editSuburbSubscription = form.getFieldInstance('edit_suburb')?.subscribe(() => {
    form.validateFields(['suburb']);
  });
  
  return () => {
    editSuburbSubscription?.unsubscribe();
  };
}, [form]);

  const fetchLocations = async () => {
    try {
      const { data, error } = await supabase
        .from('locations')
        .select('id, name');
      if (error) throw error;
      setLocations(data || []);
    } catch (error) {
      console.error('Error fetching locations:', error);
      message.error('Failed to fetch locations');
    }
  };

  const fetchUserLocation = async (userId: string) => {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('location_id')
        .eq('id', userId)
        .single();
      if (error) throw error;
      setUserLocationId(data?.location_id || null);
      setSelectedLocationId(data?.location_id || null);
    } catch (error) {
      console.error('Error fetching user location:', error);
      message.error('Failed to fetch user location');
    }
  };

  const calculateJobCounts = useCallback(() => {
    const today = dayjs().startOf('day');
    const tomorrow = today.add(1, 'day');
    const todayCount = jobs.filter(job => dayjs(job.delivery_date).isSame(today, 'day')).length;
    const tomorrowCount = jobs.filter(job => dayjs(job.delivery_date).isSame(tomorrow, 'day')).length;
    setTodayJobsCount(todayCount);
    setTomorrowJobsCount(tomorrowCount);
  }, [jobs]);

  useEffect(() => {
    calculateJobCounts();
  }, [calculateJobCounts]);

  useEffect(() => {
    fetchJobs();
    fetchTrucks();
    fetchCustomers();
    fetchProducts();
    fetchNextJobNumber();
  }, [fetchJobs]);

  

  

  const handleJobChange = useCallback(async (payload: any) => {
    console.log('Change received!', payload);
    if (payload.eventType === 'UPDATE') {
      try {
        const { data, error } = await supabase
          .from('jobs')
          .select(`
            *,
            customers:customer_id (id, customer_name)
          `)
          .eq('id', payload.new.id)
          .single();

        if (error) throw error;

        setJobs(currentJobs =>
          currentJobs.map(job =>
            job.id === data.id
              ? { ...job, ...data, customer_name: data.customers?.customer_name }
              : job
          )
        );

        // Check if status needs to be updated to 'completed'
        if (data.progressive && data.quantity) {
          const progress = (parseFloat(data.progressive) / parseFloat(data.quantity)) * 100;
          if (progress >= 95 && data.status !== 'completed') {
            await supabase
              .from('jobs')
              .update({ status: 'completed' })
              .eq('id', data.id);
          }
        }
      } catch (error) {
        console.error('Error handling job change:', error);
      }
    } else if (payload.eventType === 'INSERT') {
      fetchJobs();
    } else if (payload.eventType === 'DELETE') {
      setJobs(currentJobs => currentJobs.filter(job => job.id !== payload.old.id));
    }
  }, [fetchJobs]);

  useEffect(() => {
    fetchJobs();
    const subscription = supabase
      .channel('jobs_channel')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'jobs' }, handleJobChange)
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [fetchJobs, handleJobChange]);

  const fetchTrucks = async () => {
    const { data, error } = await supabase
      .from('trucks')
      .select('*')
      .in('status', ['Active', 'Spare', 'Maintenance']);

    if (error) {
      message.error('Error fetching trucks: ' + error.message);
    } else {
      setTrucks(data || []);
    }
  };

  const fetchCustomers = async () => {
    try {
      // Fetch all customers
      const { data: customersData, error: customersError } = await supabase
        .from('customers')
        .select('id, customer_name');

      if (customersError) throw customersError;

      // Fetch order counts for each customer using a raw SQL query
      const { data: orderCountsData, error: orderCountsError } = await supabase
        .rpc('get_customer_order_counts');

      if (orderCountsError) throw orderCountsError;

      // Combine the data
      const customersWithOrderCount = customersData.map(customer => {
        const orderCount = (orderCountsData as OrderCount[]).find(oc => oc.customer_id === customer.id);
        return {
          ...customer,
          order_count: orderCount ? orderCount.order_count : 0
        };
      });

      // Sort customers by order count
      customersWithOrderCount.sort((a, b) => b.order_count - a.order_count);

      setCustomers(customersWithOrderCount);
    } catch (error) {
      console.error('Error fetching customers:', error);
      message.error('Error fetching customers: ' + (error as Error).message);
    }
  };
  const fetchProducts = async () => {
    try {
      const { data, error } = await supabase
        .from('products')
        .select('id, name, type');

      if (error) throw error;

      setProducts(data || []);
    } catch (error) {
      console.error('Error fetching products:', error);
      message.error('Error fetching products: ' + (error as Error).message);
    }
  };

  const fetchNextJobNumber = async () => {
    const { data, error } = await supabase
      .from('jobs')
      .select('job_number')
      .order('job_number', { ascending: false })
      .limit(1);

    if (error) {
      message.error('Error fetching next job number: ' + error.message);
    } else {
      setNextJobNumber(data && data.length > 0 ? data[0].job_number + 1 : 1000);
    }
  };

  const parseTrucksAllocated = (trucksAllocated: string[] | string): string[] => {
    if (Array.isArray(trucksAllocated)) {
      return trucksAllocated;
    }
    if (typeof trucksAllocated === 'string') {
      try {
        const parsed = JSON.parse(trucksAllocated);
        return Array.isArray(parsed) ? parsed : [parsed];
      } catch (error) {
        console.error('Error parsing trucks_allocated:', error);
        return [trucksAllocated];
      }
    }
    return [];
  };

  const getTruckColor = (type: string): string => {
    switch (type) {
      case 'Truck and Dog':
        return 'blue';
      case 'Body Truck':
        return 'green';
      case 'Semi Tipper':
        return 'orange';
      case '8 Wheeler':
        return 'purple';
      case '10 Wheeler':
        return 'red';
      default:
        return 'default';
    }
  };

  const handleTruckSelection = async (value: string[], record: Job) => {
    const selectedTrucks = trucks.filter((truck: Truck) => value.includes(truck.fleet_number));
    const maintenanceTrucks = selectedTrucks.filter((truck: Truck) => truck.status === 'Maintenance');

    if (maintenanceTrucks.length > 0) {
      const maintenanceTruckNumbers = maintenanceTrucks.map((truck: Truck) => truck.fleet_number).join(', ');
      Modal.confirm({
        title: 'Maintenance Truck(s) Selected',
        content: `You've selected truck(s) ${maintenanceTruckNumbers} which are currently in maintenance. Do you want to proceed?`,
        onOk: () => updateJobTrucks(value, record),
        onCancel: () => {
          const updatedSelection = value.filter(fleetNumber =>
            !maintenanceTrucks.some(truck => truck.fleet_number === fleetNumber)
          );
          updateJobTrucks(updatedSelection, record);
        },
      });
    } else {
      updateJobTrucks(value, record);
    }
  };

const updateJobTrucks = async (value: string[], record: Job) => {
  try {
    const updatedFields: Partial<Job> = { trucks_allocated: value };
    
    // If trucks are added and the job wasn't already started, set status to "started"
    if (value.length > 0 && record.status !== 'started' && record.status !== 'completed') {
      updatedFields.status = 'started';
    }

    const { error } = await supabase
      .from('jobs')
      .update(updatedFields)
      .eq('id', record.id);

    if (error) throw error;

      // Get existing truck assignments for this job
      const { data: existingTruckAssignments, error: fetchTruckError } = await supabase
        .from('truck_job_assignments')
        .select('truck_id')
        .eq('job_id', record.id);

      if (fetchTruckError) throw fetchTruckError;

      // Get existing driver assignments for this job
      const { data: existingDriverAssignments, error: fetchDriverError } = await supabase
        .from('driver_job_assignments')
        .select('driver_id')
        .eq('job_id', record.id);

      if (fetchDriverError) throw fetchDriverError;

      const existingTruckIds = existingTruckAssignments?.map(assignment => assignment.truck_id) || [];
      const existingDriverIds = existingDriverAssignments?.map(assignment => assignment.driver_id) || [];

      // Find new trucks to assign
      const newTrucks = trucks.filter(truck =>
        value.includes(truck.fleet_number) && !existingTruckIds.includes(truck.id)
      );

      // Insert new assignments
      if (newTrucks.length > 0) {
        const newTruckAssignments = newTrucks.map(truck => ({
          job_id: record.id,
          truck_id: truck.id,
          assignment_date: record.delivery_date
        }));

        const { error: insertTruckError } = await supabase
          .from('truck_job_assignments')
          .insert(newTruckAssignments);

        if (insertTruckError) throw insertTruckError;

        // Get drivers for the new trucks
        const { data: driversData, error: driversError } = await supabase
          .from('drivers')
          .select('id, fleet_number')
          .in('fleet_number', newTrucks.map(t => t.fleet_number));

        if (driversError) throw driversError;

        // Insert new driver assignments
        const newDriverAssignments = driversData
          ?.filter(driver => !existingDriverIds.includes(driver.id))
          .map(driver => ({
            job_id: record.id,
            driver_id: driver.id,
            assignment_date: record.delivery_date
          }));

        if (newDriverAssignments && newDriverAssignments.length > 0) {
          const { error: insertDriverError } = await supabase
            .from('driver_job_assignments')
            .insert(newDriverAssignments);

          if (insertDriverError) throw insertDriverError;
        }
      }

      message.success('Trucks and drivers updated successfully');
      fetchJobs(); // Refresh the jobs list
    } catch (error) {
      console.error('Error updating trucks and drivers:', error);
      message.error('Failed to update trucks and drivers');
    }
  };

  useEffect(() => {
  if (modalVisible) {
    const input = document.getElementById('delivery_address') as HTMLInputElement;
    if (input && window.google && window.google.maps && window.google.maps.places) {
      autocompleteRef.current = new window.google.maps.places.Autocomplete(input, {
        types: ['address'],
        componentRestrictions: { country: 'AU' },
      });

      const handlePlaceSelect = () => {
        const place = autocompleteRef.current?.getPlace();
        if (place && place.address_components) {
          let streetNumber = '';
          let streetName = '';
          let suburbName = '';

          for (const component of place.address_components) {
            const componentType = component.types[0];
            switch (componentType) {
              case 'street_number':
                streetNumber = component.long_name;
                break;
              case 'route':
                streetName = component.long_name;
                break;
              case 'locality':
                suburbName = component.long_name;
                break;
            }
          }

          const fullAddress = `${streetNumber} ${streetName}`;
          form.setFieldsValue({ 
            delivery_address: fullAddress, 
            suburb: suburbName,
            edit_suburb: false
          });
        }
      };

      autocompleteRef.current.addListener('place_changed', handlePlaceSelect);
    }
  }
}, [modalVisible, form]);

  const handlePlaceSelect = useCallback(() => {
    const place = autocompleteRef.current?.getPlace();
    if (place && place.address_components) {
      let streetNumber = '';
      let streetName = '';
      let suburbName = '';

      for (const component of place.address_components) {
        const componentType = component.types[0];
        switch (componentType) {
          case 'street_number':
            streetNumber = component.long_name;
            break;
          case 'route':
            streetName = component.long_name;
            break;
          case 'locality':
            suburbName = component.long_name;
            break;
        }
      }

      const fullAddress = `${streetNumber} ${streetName}`;
      form.setFieldsValue({ 
        delivery_address: fullAddress, 
        suburb: suburbName,
        edit_suburb: false
      });
    }
  }, [form]);
  useEffect(() => {
    if (modalVisible) {
      const input = document.getElementById('delivery_address') as HTMLInputElement;
      if (input) {
        autocompleteRef.current = new google.maps.places.Autocomplete(input, {
          types: ['address'],
          componentRestrictions: { country: 'AU' },
        });

        autocompleteRef.current.addListener('place_changed', handlePlaceSelect);
      }
    }
  }, [modalVisible, handlePlaceSelect]);

  const handleSaveJob = async (values: any) => {
  try {
    let delivery_time = values.delivery_time;
    if (delivery_time === 'custom') {
      delivery_time = values.custom_delivery_time.format('HH:mm');
    }

    const formattedValues = {
      ...values,
      job_number: editingJob ? editingJob.job_number : nextJobNumber,
      delivery_date: values.delivery_date ? values.delivery_date.format('YYYY-MM-DD') : null,
      delivery_time: delivery_time,
      customer_id: values.customer_id,
      location_id: selectedLocationId,
      truck_type: values.truck_type,
    };

    // Remove custom_delivery_time from formattedValues
    delete formattedValues.custom_delivery_time;

    // Remove edit_suburb from formattedValues as it's not needed in the database
    delete formattedValues.edit_suburb;

    let docketId: string;

    if (editingJob) {
      const { error } = await supabase
        .from('jobs')
        .update(formattedValues)
        .eq('id', editingJob.id);

      if (error) throw error;
      docketId = editingJob.id;
      message.success('Job updated successfully');
    } else {
      const { data, error } = await supabase
        .from('jobs')
        .insert(formattedValues)
        .select();

      if (error) throw error;
      if (!data || data.length === 0) throw new Error('No data returned after job creation');
      docketId = data[0].id;
      message.success('Job created successfully');
      setNextJobNumber(nextJobNumber + 1);

      // Add to customer_previous_orders
      const { error: previousOrderError } = await supabase
        .from('customer_previous_orders')
        .insert({
          customer_id: formattedValues.customer_id,
          job_id: docketId
        });

      if (previousOrderError) {
        console.error('Error adding to customer_previous_orders:', previousOrderError);
      }
    }

    setModalVisible(false);
    form.resetFields();
    fetchJobs();
  } catch (error) {
    console.error('Error saving job:', error);
    message.error('Error saving job: ' + (error as Error).message);
  }
};

  const handleDeleteJob = async (id: string) => {
    try {
      // We don't delete truck_job_assignments entries
      const { error } = await supabase
        .from('jobs')
        .delete()
        .eq('id', id);

      if (error) throw error;
      message.success('Job deleted successfully');
      fetchJobs();
    } catch (error) {
      message.error('Error deleting job: ' + (error as Error).message);
    }
  };

  const handleInlineEdit = async (record: Job, dataIndex: keyof Job, value: any) => {
  try {
    let updatedValue = value;
    let updatedFields: Partial<Job> = { [dataIndex]: updatedValue };
  
    if (dataIndex === 'status' || dataIndex === 'quantity' || dataIndex === 'progressive') {
      const progress = calculateProgress(record);
      if (progress === 100 && record.status !== 'completed') {
        updatedFields.status = 'completed';
      }
    }

    const { data, error } = await supabase
      .from('jobs')
      .update(updatedFields)
      .eq('id', record.id)
      .select('*')
      .single();

    if (error) throw error;

    message.success('Job updated successfully');
  
    // Update the local state immediately
    setJobs(prevJobs => prevJobs.map(job =>
      job.id === record.id ? { ...job, ...data } : job
    ));
  } catch (error) {
    console.error('Error updating job:', error);
    message.error('Failed to update job');
  }
};

const calculateProgress = (job: Job) => {
  if (!job.quantity || !job.progressive) return 0;
  const progress = (parseFloat(job.progressive) / parseFloat(job.quantity)) * 100;
  return Math.min(Math.round(progress), 100);
};
  const handleViewDetails = (jobId: string) => {
    setSelectedJobId(jobId);
    setDrawerVisible(true);
  };

  const columns: ColumnsType<Job> = [
  {
    title: 'Time',
    dataIndex: 'delivery_time',
    key: 'delivery_time',
    width: 100,
    sorter: (a, b) => {
      const timeOrder = { 'First': 1, 'Second': 2, 'AM': 3, 'PM': 4 };
      const aOrder = timeOrder[a.delivery_time as keyof typeof timeOrder] || 5;
      const bOrder = timeOrder[b.delivery_time as keyof typeof timeOrder] || 5;
      if (aOrder === bOrder) {
        // If both are custom times, compare them directly
        return a.delivery_time.localeCompare(b.delivery_time);
      }
      return aOrder - bOrder;
    },
    render: (text: string) => {
      if (['First', 'Second', 'AM', 'PM'].includes(text)) {
        return text;
      } else {
        return dayjs(text, 'HH:mm').format('HH:mm');
      }
    },
  },
    {
      title: 'Job #',
      dataIndex: 'job_number',
      key: 'job_number',
      width: 100,
      sorter: (a, b) => a.job_number - b.job_number,
      render: (text: number, record: Job) => (
        <Tooltip title={`Truck Type: ${record.truck_type}`} mouseEnterDelay={0.5}>
          <div
            style={{
              background: '#ffffff',
              padding: '4px 8px',
              borderRadius: '4px',
              display: 'inline-block',
              border: `1px solid ${getTruckColor(record.truck_type)}`,
            }}
          >
            <Typography.Text
              strong
              style={{
                color: getTruckColor(record.truck_type),
                textShadow: '0px 0px 1px rgba(0,0,0,0.1)',
              }}
            >
              {text}
            </Typography.Text>
          </div>
        </Tooltip>
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search job number"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => clearFilters && clearFilters()} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) => record.job_number.toString().toLowerCase().includes((value as string).toLowerCase()),
    },
    {
      title: 'Customer',
      dataIndex: 'customer_name',
      key: 'customer_name',
      width: 200,
      sorter: (a, b) => (a.customer_name || '').localeCompare(b.customer_name || ''),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search customer"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => clearFilters && clearFilters()} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) => record.customer_name.toLowerCase().includes((value as string).toLowerCase()),
    },
    {
      title: 'Delivery Address',
      dataIndex: 'delivery_address',
      key: 'delivery_address',
      width: 350,
      render: (text: string, record: Job) => (
        <span>{`${text}${record.suburb ? `, ${record.suburb}` : ''}`}</span>
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search address"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => clearFilters && clearFilters()} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) => record.delivery_address.toLowerCase().includes((value as string).toLowerCase()),
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      width: 100,
      render: (text: string) => <span>{text}</span>,
      filters: products.map(product => ({ text: product.name, value: product.name })),
      onFilter: (value, record) => record.product === value,
    },

    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 100,
      sorter: (a, b) => parseFloat(a.quantity) - parseFloat(b.quantity),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search quantity"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => clearFilters && clearFilters()} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) => record.quantity.toString().includes((value as string)),
      render: (text: string, record: Job) => (
        <Input
          value={text}
          onChange={(e) => handleInlineEdit(record, 'quantity', e.target.value)}
          onPressEnter={(e) => handleInlineEdit(record, 'quantity', (e.target as HTMLInputElement).value)}
        />
      ),
    },
    {
      title: 'Trucks',
      dataIndex: 'trucks_allocated',
      key: 'trucks_allocated',
      width: 'auto',
      filters: trucks.map(truck => ({ text: truck.fleet_number, value: truck.fleet_number })),
      onFilter: (value, record) => parseTrucksAllocated(record.trucks_allocated).includes(value as string),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search trucks"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => clearFilters && clearFilters()} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      render: (trucksAllocated: string[] | string, record: Job) => {
        const parsedTrucks = parseTrucksAllocated(trucksAllocated);
        return (
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            value={parsedTrucks}
            onChange={(value) => handleTruckSelection(value, record)}
          >
            {trucks.map((truck: Truck) => (
              <Option
                key={truck.fleet_number}
                value={truck.fleet_number}
                disabled={truck.status === 'Inactive' || truck.status === 'For Sale'}
              >
                <Tag color={getTruckColor(truck.type)}>{truck.fleet_number}</Tag>
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 30,
      filters: statusOptions.map(option => ({ text: option.label, value: option.value })),
      onFilter: (value, record) => record.status === value,
      render: (status: string, record: Job) => (
        <Select
          value={status}
          style={{ width: 120 }}
          onChange={(value) => handleInlineEdit(record, 'status', value)}
          onSelect={() => {
            (document.activeElement as HTMLElement).blur();
          }}
        >
          {statusOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      ),
    },
    {
  title: 'Progress',
  dataIndex: 'progress',
  key: 'progress',
  width: 120,
  render: (_, record: Job) => {
    const progressive = parseFloat(record.progressive || '0');
    const quantity = parseFloat(record.quantity || '1');
    const progress = Math.min(Math.round((progressive / quantity) * 100), 100);
    return (
      <span>{progressive.toFixed(2)} / {progress}%</span>
    );
  },
},
    {
      title: 'Actions',
      key: 'actions',
      width: 100,
      render: (_, record) => (
        <Space size="small">
          <Button
            icon={<EditTwoTone twoToneColor="#1890ff" />}
            size="small"
            onClick={() => {
              setEditingJob(record);
              form.setFieldsValue({
                ...record,
                delivery_date: record.delivery_date ? dayjs(record.delivery_date) : null,
                trucks_allocated: parseTrucksAllocated(record.trucks_allocated),
              });
              setModalVisible(true);
            }}
          />
          <Popconfirm
            title="Are you sure you want to delete this job?"
            onConfirm={() => handleDeleteJob(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<DeleteTwoTone twoToneColor="#ff4d4f" />} size="small" />
          </Popconfirm>
          <Button
            icon={<EyeOutlined />}
            size="small"
            onClick={() => handleViewDetails(record.id)}
          />
        </Space>
      ),
    },
  ];

  return (
  <div className="daily-planner-wrapper">
    <div className="content-container">
        <div style={{ marginBottom: 16 }}>
          <Row justify="space-between" align="middle">
            <Col span={8}>
              <Title level={2}>Daily Planner</Title>
            </Col>
            <Col span={8} style={{ textAlign: 'center' }}>
              <span>
                Jobs Today: {todayJobsCount} | Tomorrow: {tomorrowJobsCount}
              </span>
            </Col>
            <Col span={8} style={{ textAlign: 'right' }}>
              <Space>
                <Select
                  value={selectedLocationId}
                  onChange={(value) => setSelectedLocationId(value)}
                  style={{ width: 200 }}
                >
                  <Option value={userLocationId}>My Location</Option>
                  {locations.map(location => (
                    <Option key={location.id} value={location.id}>{location.name}</Option>
                  ))}
                </Select>
                <Button
                  type="primary"
                  onClick={() => {
                    setEditingJob(null);
                    form.resetFields();
                    form.setFieldsValue({ job_number: nextJobNumber });
                    setModalVisible(true);
                  }}
                  icon={<PlusOutlined />}
                >
                  Add New Job
                </Button>
                <DatePicker
                  defaultValue={filterDate}
                  format="DD-MM-YYYY"
                  onChange={(date) => setFilterDate(date || dayjs())}
                />
              </Space>
            </Col>
          </Row>
        </div>
        <div className="content-wrapper-full">
          <Table
            columns={columns}
            dataSource={jobs.filter((job: Job) => dayjs(job.delivery_date).isSame(filterDate, 'day'))}
            loading={loading}
            rowKey="id"
            pagination={false}
            style={{ width: '100%' }}
            rowClassName={(record) => {
              const status = record.status ? record.status.toLowerCase().replace(' ', '-') : 'entered';
              return `status-${status} job-row`;
            }}
            onRow={(record) => {
              const status = record.status ? record.status.toLowerCase().replace(' ', '-') : 'entered';
              const colors = {
                'entered': '#ffffff',
                'started': '#fafa7a',
                'completed': '#7afa8e',
                'on-hold': '#faab7a',
                'cancelled': '#fa7a7a',
                'moved': '#7adcfa'
              };
              return {
                style: { backgroundColor: colors[status as keyof typeof colors] || colors.entered }
              };
            }}
          />
        </div>
      </div>
    <Modal
        title={
          <Space>
            <CalendarOutlined />
            {editingJob ? "Edit Job" : "Create New Job"}
          </Space>
        }
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setEditingJob(null);
          form.resetFields();
        }}
        footer={null}
        width={800}
      >
        <Form form={form} onFinish={handleSaveJob} layout="vertical">
  <Row gutter={16}>
    <Col span={8}>
      <Form.Item name="job_number" label="Job Number">
  <Typography.Text strong style={{ fontSize: '18px', color: '#1890ff' }}>
    {nextJobNumber}
  </Typography.Text>
</Form.Item>
    </Col>
    <Col span={8}>
      <Form.Item
        name="delivery_date"
        label="Delivery Date"
        rules={[{ required: true, message: 'Please select the delivery date!' }]}
      >
        <DatePicker style={{ width: '100%' }} format="DD-MM-YYYY" />
      </Form.Item>
    </Col>
    <Col span={8}>
      <Form.Item
        name="delivery_time"
        label="Delivery Time"
        rules={[{ required: true, message: 'Please select the delivery time!' }]}
      >
        <Select style={{ width: '100%' }}>
          <Option value="First">First</Option>
          <Option value="Second">Second</Option>
          <Option value="AM">AM</Option>
          <Option value="PM">PM</Option>
          <Option value="custom">Custom Time</Option>
        </Select>
      </Form.Item>
    </Col>
  </Row>

  <Form.Item
    noStyle
    shouldUpdate={(prevValues, currentValues) => prevValues.delivery_time !== currentValues.delivery_time}
  >
    {({ getFieldValue }) => 
      getFieldValue('delivery_time') === 'custom' ? (
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="custom_delivery_time"
              label="Custom Delivery Time"
              rules={[{ required: true, message: 'Please input the custom delivery time!' }]}
            >
              <TimePicker format="HH:mm" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
      ) : null
    }
  </Form.Item>

  <Row gutter={16}>
  <Col span={12}>
    <Form.Item
      name="customer_id"
      label="Customer"
      rules={[{ required: true, message: 'Please select the customer!' }]}
    >
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label as string).toLowerCase().includes(input.toLowerCase())
        }
        onSelect={() => {
          (document.activeElement as HTMLElement).blur();
        }}
      >
        {customers.map((customer: Customer) => (
          <Option key={customer.id} value={customer.id} label={customer.customer_name}>
            {customer.customer_name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  </Col>
  <Col span={12}>
    <Form.Item
      name="product"
      label="Product"
      rules={[{ required: true, message: 'Please select the product!' }]}
    >
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label as string).toLowerCase().includes(input.toLowerCase())
        }
        onSelect={() => {
          (document.activeElement as HTMLElement).blur();
        }}
      >
        {products.map((product: Product) => (
          <Option key={product.id} value={product.name} label={product.name}>
            {product.name}{product.type ? ` (${product.type})` : ''}
          </Option>
        ))}
      </Select>
    </Form.Item>
  </Col>
</Row>

  <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="delivery_address"
                label="Delivery Address"
                rules={[{ required: true, message: 'Please input the delivery address!' }]}
              >
                <Input id="delivery_address" />
              </Form.Item>
            </Col>
            <Col span={12}>
  <Form.Item
    name="suburb"
    label="Suburb"
    rules={[{ required: true, message: 'Please input the suburb!' }]}
  >
    <Input />
  </Form.Item>
</Col>
          </Row>

        <div style={{ marginBottom: '24px' }}></div>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="quantity"
              label="Quantity"
              rules={[{ required: true, message: 'Please input the quantity!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
  <Form.Item
    name="contact_number"
    label="Contact Number"
    rules={[
      { required: true, message: 'Please input the contact number!' },
      {
        validator: (_, value) => {
          if (!value) return Promise.resolve();
          const cleanedNumber = value.replace(/\D/g, '');
          if (cleanedNumber.startsWith('04') && cleanedNumber.length !== 10) {
            return Promise.reject('Mobile numbers must be 10 digits');
          } else if (!cleanedNumber.startsWith('04') && ![8, 10].includes(cleanedNumber.length)) {
            return Promise.reject('Landline numbers must be 8 or 10 digits');
          }
          return Promise.resolve();
        }
      }
    ]}
  >
    <Input
      onChange={(e) => {
        const value = e.target.value.replace(/\D/g, '');
        let formattedNumber = value;
        if (value.startsWith('04')) {
          formattedNumber = value.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3');
        } else if (value.length === 10) {
          formattedNumber = value.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
        } else {
          formattedNumber = value.replace(/(\d{4})(\d{4})/, '$1-$2');
        }
        form.setFieldsValue({ contact_number: formattedNumber });
      }}
    />
  </Form.Item>
</Col>
          <Col span={8}>
            <Form.Item
              name="site_contact_name"
              label="Site Contact Name"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="uhf_channel"
              label="UHF Channel"
              rules={[
                { required: false },
                { type: 'number', min: 1, max: 80, message: 'UHF Channel must be between 1 and 80' }
              ]}
            >
              <InputNumber min={1} max={80} style={{ width: '80px' }} />
            </Form.Item>
          </Col>
          </Row>
          
          <Row gutter={16}>
  <Col span={12}>
    <Form.Item
      name="truck_type"
      label="Truck Type"
      rules={[{ required: true, message: 'Please select the truck type!' }]}
    >
      <Select>
        <Option value="Truck and Dog">Truck and Dog</Option>
        <Option value="Body Truck">Body Truck</Option>
        <Option value="Semi">Semi</Option>
        <Option value="10 Wheeler">10 Wheeler</Option>
        <Option value="8 Wheeler">8 Wheeler</Option>
        <Option value="Side Tipper">Side Tipper</Option>
      </Select>
    </Form.Item>
            </Col>
            </Row>

        <Form.Item
          name="notes"
          label="Notes"
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
            {editingJob ? 'Update Job' : 'Create Job'}
          </Button>
          <Button onClick={() => setModalVisible(false)}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Modal>
    <DetailsDrawer
      visible={drawerVisible}
      onClose={() => setDrawerVisible(false)}
      jobId={selectedJobId}
    />
  </div>
);
};

export default DailyPlanner;