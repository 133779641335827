import React from 'react';
import dayjs from 'dayjs';
import { karremanLogoBase64, bsiLogoBase64 } from '../utils/base64Images';

interface PrintTemplateProps {
  docketData: {
    docket_number: string;
    plant?: string;
    customer: string;
    customer_number?: string;
    delivery_address: string;
    order_number?: string;
    product_name: string;
    product_type?: string;
    product_code?: string;
    lot_number?: string;
    xbin_job_number?: string;
    job_number?: string;
    fleet_number: string;
    progressive: number;
    gross_weight: number;
    tare_weight: number;
    net_weight: number;
  };
  type: 'docket' | 'xbinDocket';
}

const styles = `
  body {
    font-family: Arial, sans-serif;
    font-size: 10pt;
    line-height: 1.2;
    margin: 0;
    padding: 0;
  }
  .docket {
    border: 1px solid #000;
    padding: 5mm;
    margin-bottom: 5mm;
  }
  .header {
    text-align: center;
    margin-bottom: 2mm;
  }
  .title {
    font-size: 16pt;
    font-weight: bold;
    margin-bottom: 2mm;
  }
  .logo-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2mm;
  }
  .logo {
    max-width: 30mm;
    max-height: 20mm;
    width: auto;
    height: auto;
  }
  .company-info {
    text-align: center;
    font-size: 8pt;
  }
  .company-info p {
    margin: 0;
  }
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1mm;
  }
  .col-3 {
    width: 33%;
  }
  .col-2 {
    width: 50%;
  }
  .right-align {
    text-align: right;
  }
  .info-label {
    font-weight: bold;
  }
  .info-value {
    display: block;
  }
  .weights {
    text-align: right;
    margin-top: 2mm;
  }
  .signature-area {
    margin-top: 2mm;
    border-top: 1px solid #000;
    padding-top: 2mm;
  }
  .footer {
    font-size: 7pt;
    text-align: center;
    margin-top: 2mm;
  }
  @media print {
    .page-break {
      page-break-after: always;
    }
  }
`;

export const PrintTemplate: React.FC<PrintTemplateProps> = ({ docketData, type }) => {
  const isXbinDocket = type === 'xbinDocket';

  const DocketContent = () => (
    <div className="docket">
      <div className="header">
        <div className="title">KARREMAN QUARRIES PTY LTD</div>
        <div className="logo-container">
          <img src={`data:image/jpeg;base64,${karremanLogoBase64}`} alt="Karreman Logo" className="logo" />
          <div className="company-info">
            <p>ACN 010 168 742</p>
            <p>ATF Karreman Quarries Trust ABN 42 482 600 638</p>
            <p>PO Box 716, Cleveland QId 4163</p>
            <p>Phone: (07) 3206 6311     Fax: (07) 3206 6136</p>
            <p>WEIGHBRIDGE: (07) 3206 6155</p>
          </div>
          <img src={`data:image/png;base64,${bsiLogoBase64}`} alt="BSI ISO9001 Logo" className="logo" />
        </div>
      </div>
      <div className="row">
        <div className="col-3"><span className="info-label">TIME OUT:</span> {dayjs().format('HH:mm:ss')}</div>
        <div className="col-3"><span className="info-label">DATE:</span> {dayjs().format('DD/MM/YYYY')}</div>
        <div className="col-3 right-align"><span className="info-label">PLANT:</span> {docketData.plant || 'N/A'}</div>
      </div>
      <div className="row">
        <div className="col-2">
          <span className="info-label">CUSTOMER:</span>
          <span className="info-value">{docketData.customer}</span>
        </div>
        <div className="col-2 right-align">
          <span className="info-label">CUSTOMER #:</span>
          <span className="info-value">{docketData.customer_number || 'N/A'}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-2">
          <span className="info-label">DELIVERY TO:</span>
          <span className="info-value">{isXbinDocket ? 'xBin' : docketData.delivery_address}</span>
        </div>
        <div className="col-2 right-align">
          <span className="info-label">ORDER #:</span>
          <span className="info-value">{docketData.order_number || 'N/A'}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-2">
          <span className="info-label">PRODUCT:</span>
          <span className="info-value">{docketData.product_name} {docketData.product_type ? `(${docketData.product_type})` : ''}</span>
        </div>
        <div className="col-2 right-align">
          <span className="info-label">DOCKET #:</span>
          <span className="info-value">{docketData.docket_number}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-3"><span className="info-label">PRODUCT CODE:</span> {docketData.product_code || 'N/A'}</div>
        <div className="col-3"><span className="info-label">LOT #:</span> {docketData.lot_number || 'N/A'}</div>
        <div className="col-3 right-align"><span className="info-label">JOB #:</span> {isXbinDocket ? docketData.xbin_job_number : docketData.job_number || 'N/A'}</div>
      </div>
      <div className="row">
        <div className="col-3"><span className="info-label">REGISTRATION #:</span> {docketData.fleet_number}</div>
        <div className="col-3 right-align"><span className="info-label">FLEET #:</span> {docketData.fleet_number}</div>
      </div>
      {isXbinDocket && (
        <div className="row">
          <div className="col-2 right-align"><span className="info-label">PREVIOUS PROGRESSIVE:</span> {(docketData.progressive - docketData.net_weight).toFixed(2)} t</div>
        </div>
      )}
      <div className="row">
        <div className="col-2"></div>
        <div className="col-2 right-align"><span className="info-label">PROGRESSIVE:</span> {docketData.progressive.toFixed(2)} t</div>
      </div>
      <div className="weights">
        <div><span className="info-label">GROSS:</span> {docketData.gross_weight.toFixed(2)} t</div>
        <div><span className="info-label">TARE:</span> {docketData.tare_weight.toFixed(2)} t</div>
        <div><span className="info-label">NETT:</span> {docketData.net_weight.toFixed(2)} t</div>
        <div style={{ marginTop: '1mm' }}><span className="info-label">SUB TOTAL:</span> $0.00</div>
        <div><span className="info-label">G.S.T 10%:</span> $0.00</div>
        <div><span className="info-label">JOB TOTAL:</span> $0.00</div>
      </div>
      <div className="row" style={{ marginTop: '2mm' }}>
        <span className="info-label">ADDITIONAL CHARGES:</span><br />
        <span className="info-value"></span>
      </div>
      <div className="row">
        <div className="col-2"><span className="info-label">Time In:</span> ________</div>
        <div className="col-2 right-align"><span className="info-label">Time Out:</span> ________</div>
      </div>
      <div className="signature-area">
        <p>Material Received and Checked</p>
        <p>___________________________________</p>
        <p>Signature For and on Behalf of Purchaser</p>
      </div>
      <div className="footer">
        <p>Terms and Conditions: https://www.karreman.com.au/index.php/terms-and-conditions</p>
      </div>
    </div>
  );

  return (
    <html>
      <head>
        <title>{isXbinDocket ? 'xBin' : ''} Docket #{docketData.docket_number}</title>
        <style>{styles}</style>
      </head>
      <body>
        <DocketContent />
        <div className="page-break"></div>
        <DocketContent />
      </body>
    </html>
  );
};