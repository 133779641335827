

const theme = {
  token: {
    colorPrimary: '#0D1B2A',
    colorSuccess: '#4CAF50',
    colorWarning: '#FF9800',
    colorError: '#F44336',
    borderRadius: 8,
  },
  components: {
    Table: {
      rowHoverBg: 'transparent',
      cellHoverBg: 'transparent',
    },
  },
};

export default theme;