import React, { useState, useEffect, useCallback } from 'react';
import { Form, InputNumber, Button, message, Card, Typography, Row, Col, Tag, Spin } from 'antd';
import { supabase } from '../../utils/supabaseClient';
import { FileTextOutlined, CreditCardOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const { Title, Text } = Typography;

interface CreditProps {
  docketId: string;
  onCreditCreated: () => void;
}

const Credit: React.FC<CreditProps> = ({ docketId, onCreditCreated }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [originalDocket, setOriginalDocket] = useState<any>(null);

  const fetchOriginalDocket = useCallback(async () => {
    setLoading(true);
    try {
      const { data: docketData, error: docketError } = await supabase
        .from('pulse_dockets')
        .select('*')
        .eq('id', docketId)
        .single();

      if (docketError) throw docketError;

      let jobData = null;
      if (docketData.job_id) {
        const { data: job, error: jobError } = await supabase
          .from('jobs')
          .select('id, job_number, progressive')
          .eq('id', docketData.job_id)
          .single();

        if (jobError) throw jobError;
        jobData = job;
      }

      const { data: product, error: productError } = await supabase
        .from('products')
        .select('name, type')
        .eq('id', docketData.product_id)
        .single();

      if (productError) throw productError;

      const { data: customer, error: customerError } = await supabase
        .from('customers')
        .select('customer_name')
        .eq('id', docketData.customer_id)
        .single();

      if (customerError) throw customerError;

      const combinedData = {
        ...docketData,
        job: jobData,
        product: product,
        customer: customer.customer_name
      };

      setOriginalDocket(combinedData);
      form.setFieldsValue({
        docket_number: combinedData.docket_number,
        product: `${product.name}${product.type ? ` (${product.type})` : ''}`,
        quantity: combinedData.quantity,
        net_weight: combinedData.net_weight,
      });
    } catch (error) {
      console.error('Error fetching original docket:', error);
      message.error('Failed to fetch original docket');
    } finally {
      setLoading(false);
    }
  }, [docketId, form]);

  useEffect(() => {
    fetchOriginalDocket();
  }, [fetchOriginalDocket]);

  const handleCreditSubmit = async (values: any) => {
    setLoading(true);
    try {
      const { data: maxDocketNumber } = await supabase
        .from('pulse_dockets')
        .select('docket_number')
        .order('docket_number', { ascending: false })
        .limit(1)
        .single();

      const newDocketNumber = (maxDocketNumber?.docket_number || 0) + 1;

      const creditDocket = {
        docket_number: newDocketNumber,
        job_id: originalDocket.job_id,
        truck_id: originalDocket.truck_id,
        customer_id: originalDocket.customer_id,
        delivery_address: originalDocket.delivery_address,
        product_id: originalDocket.product_id,
        quantity: -values.quantity,
        net_weight: -values.net_weight,
        gross_weight: originalDocket.gross_weight,
        tare_weight: originalDocket.tare_weight,
        is_credit: true,
        parent_docket_id: docketId,
        fleet_number: originalDocket.fleet_number,
        is_xbin: originalDocket.is_xbin,
        xbin_job_number: originalDocket.xbin_job_number,
        order_number: originalDocket.order_number,
      };

      const { error } = await supabase
        .from('pulse_dockets')
        .insert(creditDocket);

      if (error) throw error;

      // Update job progressive if it's not an xbin docket
      if (originalDocket.job && !originalDocket.is_xbin) {
        const newProgressive = originalDocket.job.progressive - values.quantity;
        const { error: jobError } = await supabase
          .from('jobs')
          .update({ progressive: newProgressive })
          .eq('id', originalDocket.job_id);

        if (jobError) throw jobError;
      }

      message.success('Credit docket created successfully');
      onCreditCreated();
    } catch (error) {
      console.error('Error creating credit docket:', error);
      message.error('Failed to create credit docket');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="credit-container">
      <Card
        title={
          <Title level={3}>
            <FileTextOutlined /> Create Credit Docket
          </Title>
        }
        extra={originalDocket && <Tag color="blue">#{originalDocket.docket_number}</Tag>}
        style={{ marginBottom: 20 }}
      >
        {originalDocket && (
          <>
            <Card title={<Title level={4}>Original Docket Details</Title>} style={{ marginBottom: 20 }}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Text strong>Customer:</Text>
                  <Text> {originalDocket.customer}</Text>
                </Col>
                <Col span={12}>
                  <Text strong>Delivery Address:</Text>
                  <Text> {originalDocket.delivery_address}</Text>
                </Col>
                <Col span={12}>
                  <Text strong>Product:</Text>
                  <Text> {`${originalDocket.product.name}${originalDocket.product.type ? ` (${originalDocket.product.type})` : ''}`}</Text>
                </Col>
                <Col span={12}>
                  <Text strong>Quantity:</Text>
                  <Text> {originalDocket.quantity.toFixed(2)} t</Text>
                </Col>
                <Col span={12}>
                  <Text strong>Truck:</Text>
                  <Text> {originalDocket.fleet_number}</Text>
                </Col>
                <Col span={12}>
                  <Text strong>Date:</Text>
                  <Text> {dayjs(originalDocket.created_at).format('DD/MM/YYYY HH:mm')}</Text>
                </Col>
              </Row>
              <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
                <Col span={8}>
                  <Card size="small" title="Tare Weight">
                    <Text strong>{originalDocket.tare_weight.toFixed(2)} t</Text>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card size="small" title="Gross Weight">
                    <Text strong>{originalDocket.gross_weight.toFixed(2)} t</Text>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card size="small" title="Net Weight">
                    <Text strong>{originalDocket.net_weight.toFixed(2)} t</Text>
                  </Card>
                </Col>
              </Row>
            </Card>
            <Card title={<Title level={4}><CreditCardOutlined /> Credit Details</Title>}>
              <Form form={form} onFinish={handleCreditSubmit} layout="vertical">
                <Row gutter={16}>
                  <Col span={8}>
                    <Text strong>Tare Weight:</Text>
                    <div>{originalDocket.tare_weight.toFixed(2)} t</div>
                  </Col>
                  <Col span={8}>
                    <Text strong>Net Weight:</Text>
                    <div>{originalDocket.net_weight.toFixed(2)} t</div>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="quantity" label="Quantity to Credit" rules={[{ required: true }]}>
                      <InputNumber
                        min={0}
                        max={originalDocket.quantity}
                        step={0.01}
                        style={{ width: '100%' }}
                        formatter={value => `${value} t`}
                        parser={value => value!.replace(' t', '')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Create Credit Docket
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </>
        )}
      </Card>
    </div>
  );
};

export default Credit;