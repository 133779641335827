import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Tag, Typography, Spin, Alert, Space, Statistic, List } from 'antd';
import { supabase } from '../utils/supabaseClient';
import dayjs from 'dayjs';

const { Title, Text } = Typography;

interface Driver {
  id: string;
  name: string;
  status: string;
}

interface Truck {
  id: string;
  fleet_number: string;
  type: string;
  status: string;
}

interface DashboardStats {
  totalCustomers: number;
  totalProducts: number;
  totalJobs: number;
}

interface WeeklyJobs {
  [key: string]: number;
}

const Dashboard: React.FC = () => {
  const [availableDrivers, setAvailableDrivers] = useState<number>(0);
  const [unavailableDrivers, setUnavailableDrivers] = useState<Driver[]>([]);
  const [trucksByStatus, setTrucksByStatus] = useState<Record<string, Truck[]>>({});
  const [availableTrucksByType, setAvailableTrucksByType] = useState<Record<string, number>>({});
  const [stats, setStats] = useState<DashboardStats | null>(null);
  const [weeklyJobs, setWeeklyJobs] = useState<WeeklyJobs>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        // Fetch drivers
        const { data: driversData, error: driversError } = await supabase
          .from('drivers')
          .select('id, name, status');
    
        if (driversError) throw driversError;
        
        const availableCount = driversData?.filter(driver => driver.status === 'Available').length || 0;
        const unavailableDriversList = driversData?.filter(driver => driver.status !== 'Available') || [];
        
        setAvailableDrivers(availableCount);
        setUnavailableDrivers(unavailableDriversList);
    
        // Fetch trucks
        const { data: trucksData, error: trucksError } = await supabase
          .from('trucks')
          .select('id, fleet_number, type, status');
    
        if (trucksError) throw trucksError;
        
        const groupedTrucks = trucksData?.reduce((acc, truck) => {
          if (!acc[truck.status]) {
            acc[truck.status] = [];
          }
          acc[truck.status].push(truck);
          return acc;
        }, {} as Record<string, Truck[]>) || {};
        
        setTrucksByStatus(groupedTrucks);
    
        // Group available trucks by type
        const availableTrucks = groupedTrucks['Active'] || [];
        const truckTypes = availableTrucks.reduce((acc, truck) => {
          acc[truck.type] = (acc[truck.type] || 0) + 1;
          return acc;
        }, {} as Record<string, number>);
        
        setAvailableTrucksByType(truckTypes);
    
        // Fetch additional stats
        const [
          { count: totalCustomers },
          { count: totalProducts },
          { count: totalJobs },
        ] = await Promise.all([
          supabase.from('customers').select('id', { count: 'exact' }),
          supabase.from('products').select('id', { count: 'exact' }),
          supabase.from('jobs').select('id', { count: 'exact' }),
        ]);
    
        setStats({
          totalCustomers: totalCustomers || 0,
          totalProducts: totalProducts || 0,
          totalJobs: totalJobs || 0,
        });
    
        // Fetch weekly jobs
        const startOfWeek = dayjs().startOf('week');
        const endOfWeek = dayjs().endOf('week');
    
        const { data: jobsData, error: jobsError } = await supabase
          .from('jobs')
          .select('delivery_date')
          .gte('delivery_date', startOfWeek.format('YYYY-MM-DD'))
          .lte('delivery_date', endOfWeek.format('YYYY-MM-DD'));
    
        if (jobsError) throw jobsError;
    
        const weeklyJobCounts = jobsData?.reduce((acc, job) => {
          const day = dayjs(job.delivery_date).format('dddd');
          acc[day] = (acc[day] || 0) + 1;
          return acc;
        }, {} as WeeklyJobs);
    
        setWeeklyJobs(weeklyJobCounts || {});
    
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setError('Failed to fetch dashboard data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (error) {
    return <Alert message="Error" description={error} type="error" showIcon />;
  }

  const getStatusColor = (status: string | null | undefined): string => {
    if (!status) return '#d9d9d9'; // Default color for null or undefined status
    switch (status.toLowerCase()) {
      case 'available': return '#52c41a';
      case 'unavailable': return '#f5222d';
      case 'annual leave': return '#faad14';
      case 'wet day': return '#1890ff';
      case 'sick': return '#722ed1';
      default: return '#d9d9d9';
    }
  };
  
  const getTruckColor = (type: string): string => {
    switch (type) {
      case 'Truck and Dog': return 'blue';
      case 'Body Truck': return 'green';
      case 'Semi Tipper': return 'orange';
      case '8 Wheeler': return 'purple';
      case '10 Wheeler': return 'red';
      default: return 'default';
    }
  };

  return (
    <div className="content-container" style={{ background: 'transparent', padding: '24px' }}>
      <div style={{ marginBottom: 16 }}>
        <Row justify="space-between" align="middle">
          <Col>
            <Title level={2}>Dashboard</Title>
          </Col>
        </Row>
      </div>
      <div className="content-wrapper">
        <Row gutter={16}>
          <Col span={12}>
            <Card title="Drivers" headStyle={{ background: '#1890ff', color: 'white' }}>
              {loading ? (
                <Spin />
              ) : (
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Row justify="center">
                    <Statistic title="Available" value={availableDrivers} valueStyle={{ color: '#52c41a' }} />
                  </Row>
                  <List
  size="small"
  header={<Text strong>Unavailable Drivers</Text>}
  dataSource={unavailableDrivers}
  renderItem={driver => (
    <List.Item>
      <Text>{driver.name}</Text>
      <Text style={{ color: getStatusColor(driver.status) }}>{driver.status || 'N/A'}</Text>
    </List.Item>
  )}
/>
                </Space>
              )}
            </Card>
          </Col>
          <Col span={12}>
  <Card title="Trucks" headStyle={{ background: '#1890ff', color: 'white' }}>
    {loading ? (
      <Spin />
    ) : (
      <Space direction="vertical" style={{ width: '100%' }}>
        <Statistic title="Total Trucks" value={Object.values(trucksByStatus).flat().length} />
        <Statistic title="Active Trucks" value={trucksByStatus['Active']?.length || 0} />
        <Row>
          <Col span={12}>
            <Text strong>Spare Trucks:</Text>
          </Col>
          <Col span={12}>
            {trucksByStatus['Spare']?.map(truck => (
              <Tag key={truck.id} color={getTruckColor(truck.type)}>{truck.fleet_number}</Tag>
            ))}
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Text strong>Inactive Trucks:</Text>
          </Col>
          <Col span={12}>
            {trucksByStatus['Inactive']?.map(truck => (
              <Tag key={truck.id} color={getTruckColor(truck.type)}>{truck.fleet_number}</Tag>
            ))}
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Text strong>Trucks in Maintenance:</Text>
          </Col>
          <Col span={12}>
            {trucksByStatus['Maintenance']?.map(truck => (
              <Tag key={truck.id} color={getTruckColor(truck.type)}>{truck.fleet_number}</Tag>
            ))}
          </Col>
        </Row>
      </Space>
    )}
  </Card>
</Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 16 }}>
  <Col span={24}>
    <Card title="Available Trucks by Type" headStyle={{ background: '#1890ff', color: 'white' }}>
      {loading ? (
        <Spin />
      ) : (
        <Row gutter={16} justify="space-around">
          {Object.entries(availableTrucksByType).map(([type, count]) => (
            <Col key={type}>
              <Statistic title={type} value={count} valueStyle={{ color: '#52c41a' }} />
            </Col>
          ))}
        </Row>
      )}
    </Card>
  </Col>
</Row>
        <Row gutter={16} style={{ marginTop: 16 }}>
          <Col span={8}>
            <Card>
              <Statistic title="Total Customers" value={stats?.totalCustomers} valueStyle={{ color: '#1890ff' }} />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic title="Total Products" value={stats?.totalProducts} valueStyle={{ color: '#1890ff' }} />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic title="Total Jobs" value={stats?.totalJobs} valueStyle={{ color: '#1890ff' }} />
            </Card>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 16 }}>
  <Col span={24}>
    <Card title="Weekly Jobs" headStyle={{ background: '#1890ff', color: 'white' }}>
      {loading ? (
        <Spin />
      ) : (
        <Row gutter={16} justify="space-around">
          {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
            <Col key={day} style={{ textAlign: 'center' }}>
              <Statistic title={day} value={weeklyJobs[day] || 0} />
            </Col>
          ))}
          <Col style={{ textAlign: 'center' }}>
            <Statistic 
              title="Total" 
              value={Object.values(weeklyJobs).reduce((a, b) => a + b, 0)} 
              valueStyle={{ color: '#1890ff' }}
            />
          </Col>
        </Row>
      )}
    </Card>
  </Col>
</Row>
      </div>
    </div>
  );
};
export default Dashboard;