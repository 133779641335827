import React, { useState, useEffect } from 'react';
import { Drawer, Typography, Table, Spin, message, Card, Row, Col, Tag, } from 'antd';
import { supabase } from '../../utils/supabaseClient';
import { TruckOutlined, FileTextOutlined, EyeOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import DocketModal from './DocketModal';

const { Title, Text } = Typography;

interface JobDetailsDrawerProps {
  visible: boolean;
  onClose: () => void;
  jobId: string | null;
}

interface DocketData {
  id: string;
  docket_number: number;
  job_id: string | null;
  truck_id: string;
  customer_id: string;
  delivery_address: string;
  product_id: string;
  quantity: number;
  gross_weight: number;
  tare_weight: number;
  net_weight: number;
  is_xbin: boolean;
  xbin_job_number: string | null;
  fleet_number: string;
  created_at: string;
  order_number: string | null;
}

interface JobData {
  id: string;
  job_number: number;
  quantity: string;
  progressive: string;
  status: string;
  customer_id: string;
  truck_progress?: {
    fleet_number: string;
    progressive: number;
  }[];
}

const JobDetailsDrawer: React.FC<JobDetailsDrawerProps> = ({ visible, onClose, jobId }) => {
  const [loading, setLoading] = useState(false);
  const [docketData, setDocketData] = useState<DocketData | null>(null);
  const [jobData, setJobData] = useState<JobData | null>(null);
  const [customerName, setCustomerName] = useState<string>('');
  const [productDetails, setProductDetails] = useState<{ name: string; type: string | null } | null>(null);
  const [relatedDockets, setRelatedDockets] = useState<DocketData[]>([]);
  const [selectedDocket, setSelectedDocket] = useState<DocketData | null>(null);
  const [isDocketModalVisible, setIsDocketModalVisible] = useState(false);

  useEffect(() => {
    if (visible && jobId) {
      fetchDocketDetails(jobId);
    }
  }, [visible, jobId]);

  const fetchDocketDetails = async (id: string) => {
    setLoading(true);
    try {
      const { data: docket, error: docketError } = await supabase
        .from('pulse_dockets')
        .select('*')
        .eq('id', id)
        .single();

      if (docketError) throw docketError;

      setDocketData(docket);

      if (docket.customer_id) {
        const { data: customerData, error: customerError } = await supabase
          .from('customers')
          .select('customer_name')
          .eq('id', docket.customer_id)
          .single();

        if (customerError) throw customerError;
        setCustomerName(customerData.customer_name);
      }

      if (docket.product_id) {
        const { data: productData, error: productError } = await supabase
          .from('products')
          .select('name, type')
          .eq('id', docket.product_id)
          .single();

        if (productError) throw productError;
        setProductDetails(productData);
      }

      if (docket.is_xbin && docket.order_number) {
        const { data: relatedDocketsData, error: relatedDocketsError } = await supabase
          .from('pulse_dockets')
          .select('*')
          .eq('order_number', docket.order_number)
          .order('created_at', { ascending: true });

        if (relatedDocketsError) throw relatedDocketsError;
        setRelatedDockets(relatedDocketsData);
      } else if (docket.job_id) {
        const { data: job, error: jobError } = await supabase
          .from('jobs')
          .select('*')
          .eq('id', docket.job_id)
          .single();

        if (jobError) throw jobError;

        setJobData(job);

        const { data: truckProgress, error: progressError } = await supabase
          .rpc('get_truck_progress_for_job', { job_id: docket.job_id });

        if (progressError) throw progressError;

        setJobData(prevJob => ({
          ...prevJob!,
          truck_progress: truckProgress
        }));

        // Fetch all dockets for this job
        const { data: jobDockets, error: jobDocketsError } = await supabase
          .from('pulse_dockets')
          .select('*')
          .eq('job_id', docket.job_id)
          .order('created_at', { ascending: true });

        if (jobDocketsError) throw jobDocketsError;
        setRelatedDockets(jobDockets);
      }
    } catch (error) {
      console.error('Error fetching docket details:', error);
      message.error('Failed to fetch docket details');
    } finally {
      setLoading(false);
    }
  };
    
  const getStatusColor = (status: string): string => {
    const colors: { [key: string]: string } = {
      'entered': '#ffffff',
      'started': '#fafa7a',
      'completed': '#7afa8e',
      'on-hold': '#faab7a',
      'cancelled': '#fa7a7a',
      'moved': '#7adcfa'
    };
    return colors[status.toLowerCase().replace(' ', '-')] || '#ffffff';
  };
    
  const shouldUseDarkText = (backgroundColor: string): boolean => {
    const r = parseInt(backgroundColor.slice(1, 3), 16);
    const g = parseInt(backgroundColor.slice(3, 5), 16);
    const b = parseInt(backgroundColor.slice(5, 7), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 128;
  };

  const renderDocketDetails = () => {
    if (!docketData) return null;

    return (
      <Card
        title={<Title level={4}><FileTextOutlined /> Docket Details</Title>}
        extra={<Tag color="blue">#{docketData.docket_number}</Tag>}
        style={{ marginBottom: 20 }}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Text strong>Customer:</Text>
            <Text> {customerName}</Text>
          </Col>
          <Col span={12}>
            <Text strong>Delivery Address:</Text>
            <Text> {docketData.delivery_address}</Text>
          </Col>
          <Col span={12}>
            <Text strong>Product:</Text>
            <Text> {productDetails?.name} {productDetails?.type ? `(${productDetails.type})` : ''}</Text>
          </Col>
          <Col span={12}>
            <Text strong>Quantity:</Text>
            <Text> {docketData.quantity.toFixed(2)} t</Text>
          </Col>
          <Col span={12}>
            <Text strong>Truck:</Text>
            <Text> {docketData.fleet_number}</Text>
          </Col>
          <Col span={12}>
            <Text strong>Date:</Text>
            <Text> {dayjs(docketData.created_at).format('DD/MM/YYYY HH:mm')}</Text>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          <Col span={8}>
            <Card size="small" title="Tare Weight">
              <Text strong>{docketData.tare_weight.toFixed(2)} t</Text>
            </Card>
          </Col>
          <Col span={8}>
            <Card size="small" title="Gross Weight">
              <Text strong>{docketData.gross_weight.toFixed(2)} t</Text>
            </Card>
          </Col>
          <Col span={8}>
            <Card size="small" title="Net Weight">
              <Text strong>{docketData.net_weight.toFixed(2)} t</Text>
            </Card>
          </Col>
        </Row>
      </Card>
    );
  };

  const renderRelatedDockets = () => {
    if (!relatedDockets.length) return null;

    const columns = [
      { 
        title: 'Docket Number', 
        dataIndex: 'docket_number', 
        key: 'docket_number',
        render: (text: number) => <Tag color="blue">#{text}</Tag>
      },
      { 
        title: 'Date', 
        dataIndex: 'created_at', 
        key: 'created_at',
        render: (text: string) => dayjs(text).format('DD/MM/YYYY HH:mm')
      },
      { title: 'Quantity', dataIndex: 'quantity', key: 'quantity', render: (text: number) => `${text.toFixed(2)} t` },
      { title: 'Net Weight', dataIndex: 'net_weight', key: 'net_weight', render: (text: number) => `${text.toFixed(2)} t` },
      {
        title: 'View',
        key: 'view',
        render: (_: any, record: DocketData) => (
          <EyeOutlined
            onClick={() => {
              setSelectedDocket(record);
              setIsDocketModalVisible(true);
            }}
            style={{ cursor: 'pointer' }}
          />
        ),
      },
    ];

    return (
      <Card title={<Title level={4}>{docketData?.is_xbin ? <TruckOutlined /> : <FileTextOutlined />} Related Dockets</Title>} style={{ marginBottom: 20 }}>
        {docketData?.is_xbin && (
          <Row>
            <Col span={24}>
              <Text strong>Order Number:</Text>
              <Tag color="green" style={{ marginLeft: 8 }}>{docketData.order_number}</Tag>
            </Col>
          </Row>
        )}
        <Table 
          dataSource={relatedDockets} 
          columns={columns} 
          rowKey="id"
          pagination={false}
          size="small"
        />
      </Card>
    );
  };

  const renderJobDetails = () => {
    if (!jobData) return null;

    const columns = [
      { 
        title: 'Truck', 
        dataIndex: 'fleet_number', 
        key: 'fleet_number',
        render: (text: string) => <Tag icon={<TruckOutlined />} color="blue">{text}</Tag>
      },
      { 
        title: 'Total', 
        dataIndex: 'progressive', 
        key: 'progressive', 
        render: (text: number) => `${text.toFixed(2)} t` 
      },
      { 
        title: '% of Progressive', 
        dataIndex: 'progressive', 
        key: 'progressPercentage',
        render: (text: number) => {
          const percentage = (text / parseFloat(jobData.quantity)) * 100;
          return `${percentage.toFixed(2)}%`;
        }
      },
    ];

    return (
      <Card title={<Title level={4}><FileTextOutlined /> Job Details</Title>} style={{ marginBottom: 20 }}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Text strong>Job Number:</Text>
            <Text> {jobData.job_number}</Text>
          </Col>
          <Col span={12}>
            <Text strong>Customer:</Text>
            <Text> {customerName}</Text>
          </Col>
          <Col span={12}>
            <Text strong>Total Quantity:</Text>
            <Text> {jobData.quantity} t</Text>
          </Col>
          <Col span={12}>
            <Text strong>Job Progressive:</Text>
            <Text> {jobData.progressive} t</Text>
          </Col>
          <Col span={12}>
            <Text strong>Status: </Text>
            {(() => {
              const bgColor = getStatusColor(jobData.status);
              const textColor = shouldUseDarkText(bgColor) ? '#000000' : '#ffffff';
              return (
                <Tag color={bgColor} style={{ color: textColor }}>
                  {jobData.status}
                </Tag>
              );
            })()}
          </Col>
        </Row>
        <Title level={5} style={{ marginTop: 20, marginBottom: 16 }}>Truck Tracker</Title>
        <Table 
          dataSource={jobData.truck_progress} 
          columns={columns} 
          rowKey="fleet_number"
          pagination={false}
          size="small"
        />
      </Card>
    );
  };

  return (
    <>
      <Drawer
        title={
          <Title level={3}>
            {docketData?.is_xbin ? <TruckOutlined /> : <FileTextOutlined />}
            {" "}Docket Details
          </Title>
        }
        placement="right"
        onClose={onClose}
        open={visible}
        width={720}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Spin size="large" />
          </div>
        ) : (
          <>
            {renderDocketDetails()}
            {docketData?.is_xbin ? (
              <>
                {renderRelatedDockets()}
                {renderJobDetails()}
              </>
            ) : (
              <>
                {renderJobDetails()}
                {renderRelatedDockets()}
              </>
            )}
          </>
        )}
      </Drawer>
      <DocketModal
        visible={isDocketModalVisible}
        onClose={() => setIsDocketModalVisible(false)}
        docket={selectedDocket}
        customerName={customerName}
        productDetails={productDetails}
      />
    </>
  );
};

export default JobDetailsDrawer;