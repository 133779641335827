import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, message, Upload, Card, Popconfirm, Row, Col, Typography, AutoComplete, Space } from 'antd';
import { PlusOutlined, EditTwoTone, DeleteTwoTone, UploadOutlined, EyeTwoTone, CloseCircleOutlined } from '@ant-design/icons';
import { supabase } from '../utils/supabaseClient';
import axios from 'axios';

const { Title, Text } = Typography;

interface Customer {
  id: string;
  customer_name: string;
  primary_contact_phone: string;
  address: string;
  notes: string;
  company_logo: string;
}

interface Job {
  id: string;
  job_number: number;
  delivery_date: string;
  product: string;
  quantity: string;
  status: string;
  customer_name: string;
  site_contact_name: string;
  site_contact_phone_number: string;
  delivery_address: string;
  uhf_channel: string;
  past_trucks: string[];
  notes: string;
}

const Customers: React.FC = () => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [previousOrders, setPreviousOrders] = useState<Job[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [jobModalVisible, setJobModalVisible] = useState(false);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [form] = Form.useForm();
  const [editingCustomer, setEditingCustomer] = useState<Customer | null>(null);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    if (selectedCustomer) {
      fetchPreviousOrders(selectedCustomer.id);
    }
  }, [selectedCustomer]);

  const fetchCustomers = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('customers')
        .select('*');
      if (error) throw error;
      setCustomers(data || []);
    } catch (error) {
      message.error('Failed to fetch customers');
      console.error('Error fetching customers:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPreviousOrders = async (customerId: string) => {
    try {
      const { data, error } = await supabase
        .from('jobs')
        .select('*')
        .eq('customer_id', customerId)
        .order('delivery_date', { ascending: false });
      if (error) throw error;
      setPreviousOrders(data || []);
    } catch (error) {
      message.error('Failed to fetch previous orders');
      console.error('Error fetching previous orders:', error);
    }
  };

  const handleAddCustomer = () => {
    setEditingCustomer(null);
    form.resetFields();
    setModalVisible(true);
  };

  const handleEditCustomer = (customer: Customer) => {
    setEditingCustomer(customer);
    form.setFieldsValue(customer);
    setModalVisible(true);
  };

  const handleDeleteCustomer = async (id: string) => {
    try {
      const { error } = await supabase
        .from('customers')
        .delete()
        .eq('id', id);
      if (error) throw error;
      message.success('Customer deleted successfully');
      fetchCustomers();
      if (selectedCustomer?.id === id) {
        setSelectedCustomer(null);
        setPreviousOrders([]);
      }
    } catch (error) {
      message.error('Failed to delete customer');
      console.error('Error deleting customer:', error);
    }
  };

  const handleSaveCustomer = async (values: any) => {
    try {
      const formattedValues = {
        ...values,
        customer_number: values.customer_number, // Add this line
      };

      if (editingCustomer) {
        const { error } = await supabase
          .from('customers')
          .update(formattedValues)
          .eq('id', editingCustomer.id);
        if (error) throw error;
        message.success('Customer updated successfully');
      } else {
        const { data, error } = await supabase
          .from('customers')
          .insert(formattedValues)
          .select();
        if (error) throw error;
        message.success('Customer added successfully');
        if (data && data[0]) {
          setSelectedCustomer(data[0]);
        }
      }
      setModalVisible(false);
      fetchCustomers();
    } catch (error) {
      console.error('Error saving customer:', error);
      message.error('Failed to save customer');
    }
  };

  const handleLogoUpload = async (info: any) => {
    if (info.file.status === 'done') {
      try {
        const file = info.file.originFileObj;
        const formData = new FormData();
        formData.append('logo', file);

        const response = await axios.post('http://localhost:5000/api/upload-company-logo', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status !== 200) {
          throw new Error('Failed to upload logo');
        }

        const { filePath } = response.data;
        form.setFieldsValue({ company_logo: filePath });
        message.success('Logo uploaded successfully');
      } catch (error) {
        console.error('Error uploading logo:', error);
        message.error('Failed to upload logo');
      }
    }
  };

  const handleJobClick = (job: Job) => {
    setSelectedJob(job);
    setJobModalVisible(true);
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleClearSearch = () => {
    setSearchValue('');
  };

  const filteredCustomers = customers.filter(customer =>
    customer.customer_name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const customerColumns = [
    {
      title: 'Name',
      dataIndex: 'customer_name',
      key: 'customer_name',
      sorter: (a: Customer, b: Customer) => a.customer_name.localeCompare(b.customer_name),
    },
    {
      title: 'Contact Number',
      dataIndex: 'primary_contact_phone',
      key: 'primary_contact_phone',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: Customer) => (
        <>
          <Button icon={<EditTwoTone twoToneColor="#1B263B" />} onClick={() => handleEditCustomer(record)} />
          <Popconfirm
            title="Are you sure you want to delete this customer?"
            onConfirm={() => handleDeleteCustomer(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<DeleteTwoTone twoToneColor="#FF4D4F" />} />
          </Popconfirm>
          <Button icon={<EyeTwoTone twoToneColor="#52C41A" />} onClick={() => setSelectedCustomer(record)} />
        </>
      ),
    },
  ];

  const previousOrderColumns = [
    {
      title: 'Job Number',
      dataIndex: 'job_number',
      key: 'job_number',
      render: (text: string, record: Job) => (
        <Button type="link" onClick={() => handleJobClick(record)}>
          {text}
        </Button>
      ),
    },
    {
      title: 'Delivery Date',
      dataIndex: 'delivery_date',
      key: 'delivery_date',
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  return (
    <div className="content-container">
      <div style={{ marginBottom: 16 }}>
        <Row justify="space-between" align="middle">
          <Col>
            <Title level={2}>Customers</Title>
          </Col>
          <Col>
            <Space>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleAddCustomer}
              >
                Add Customer
              </Button>
              <AutoComplete
                style={{ width: 300 }}
                options={customers.map(customer => ({ value: customer.customer_name }))}
                placeholder="Search customers"
                filterOption={(inputValue, option) =>
                  option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                onSelect={(value) => setSearchValue(value)}
                onChange={handleSearch}
                value={searchValue}
              >
                <Input
                  suffix={
                    searchValue ? 
                      <CloseCircleOutlined 
                        onClick={() => {
                          setSearchValue('');
                          handleClearSearch();
                        }} 
                        style={{ cursor: 'pointer' }} 
                      /> 
                      : null
                  }
                />
              </AutoComplete>
            </Space>
          </Col>
        </Row>
      </div>
      <Row gutter={16}>
        <Col span={10}>
          <Table
            columns={customerColumns}
            dataSource={filteredCustomers}
            rowKey="id"
            loading={loading}
          />
        </Col>
        <Col span={14}>
          {selectedCustomer ? (
            <Card>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
                <img
                  src={`http://localhost:5000${selectedCustomer.company_logo || ''}`}
                  alt={`${selectedCustomer.customer_name} logo`}
                  style={{ width: 100, height: 100, objectFit: 'contain', marginRight: 16 }}
                />
                <div>
                  <Title level={4}>{selectedCustomer.customer_name}</Title>
                  <Text strong>Contact Number: </Text>
                  <Text>{selectedCustomer.primary_contact_phone}</Text>
                  <br />
                  <Text strong>Address: </Text>
                  <Text>{selectedCustomer.address}</Text>
                  <br />
                  <Text strong>Notes: </Text>
                  <Text>{selectedCustomer.notes || 'N/A'}</Text>
                </div>
              </div>

              <Title level={4} style={{ marginTop: 24 }}>Previous Orders</Title>
              <Table
                columns={previousOrderColumns}
                dataSource={previousOrders}
                rowKey="id"
                pagination={{ pageSize: 5 }}
              />
            </Card>
          ) : (
            <Card>
              <Text>Click the green eye icon to view details and previous orders.</Text>
            </Card>
          )}
        </Col>
      </Row>
      <Modal
      title={editingCustomer ? "Edit Customer" : "Add Customer"}
      open={modalVisible}
      onCancel={() => setModalVisible(false)}
      footer={null}
    >
      <Form form={form} onFinish={handleSaveCustomer} layout="vertical">
        <Form.Item name="customer_name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="customer_number" label="Customer Number" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="primary_contact_phone" label="Contact Number" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
          <Form.Item name="address" label="Address" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="notes" label="Notes">
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="company_logo" label="Company Logo">
            <Upload
              accept="image/*"
              customRequest={({ onSuccess }: any) => setTimeout(() => onSuccess('ok'), 0)}
              onChange={handleLogoUpload}
            >
              <Button icon={<UploadOutlined />}>Upload Logo</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingCustomer ? 'Update Customer' : 'Add Customer'}
            </Button>
          </Form.Item>
        </Form>
        </Modal>
      <Modal
        title="Job Details"
        open={jobModalVisible}
        onCancel={() => setJobModalVisible(false)}
        footer={null}
      >
        {selectedJob && (
          <div>
            <img
              src={`http://localhost:5000${selectedCustomer?.company_logo || ''}`}
              alt={`${selectedJob.customer_name} logo`}
              style={{ width: 100, height: 100, objectFit: 'contain', marginBottom: 16 }}
            />
            <p><strong>Job Number:</strong> {selectedJob.job_number}</p>
            <p><strong>Delivery Date:</strong> {selectedJob.delivery_date}</p>
            <p><strong>Customer Name:</strong> {selectedJob.customer_name}</p>
            <p><strong>Site Contact Name:</strong> {selectedJob.site_contact_name}</p>
            <p><strong>Site Contact Phone Number:</strong> {selectedJob.site_contact_phone_number}</p>
            <p><strong>Delivery Address:</strong> {selectedJob.delivery_address}</p>
            <p><strong>UHF Channel:</strong> {selectedJob.uhf_channel}</p>
            <p><strong>Product:</strong> {selectedJob.product}</p>
            <p><strong>Quantity:</strong> {selectedJob.quantity}</p>
            <p><strong>Past Trucks:</strong> {selectedJob.past_trucks?.join(', ') || 'N/A'}</p>
            <p><strong>Notes:</strong> {selectedJob.notes || 'N/A'}</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Customers;