import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, InputNumber, message, Typography, Row, Col, Space, Tooltip, AutoComplete, Select } from 'antd';
import { PlusOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import { supabase } from '../utils/supabaseClient';
import { ColumnType } from 'antd/es/table';

const { Title } = Typography;
const { Option } = Select;

interface Product {
  id: string;
  name: string;
  type: 'Drainage' | 'Blue Metal' | 'Brown';
  category: 'RoadBase' | 'Aggregate' | 'Sand' | 'Select Fill';
  product_code?: string;
  lot_number?: string;
  description?: string;
  tonnes_per_cube?: number;
  price_per_tonne?: number;
}

const Products: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingProduct, setEditingProduct] = useState<Product | null>(null);
  const [form] = Form.useForm();
  const [searchValue, setSearchValue] = useState('');
  const [filteredInfo, setFilteredInfo] = useState<Record<string, any>>({});
  const [sortedInfo, setSortedInfo] = useState<any>({});

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('products')
        .select('*');

      if (error) throw error;

      setProducts(data || []);
    } catch (error) {
      console.error('Error fetching products:', error);
      message.error('Failed to fetch products');
    } finally {
      setLoading(false);
    }
  };

  const handleAddProduct = () => {
    setEditingProduct(null);
    form.resetFields();
    setModalVisible(true);
  };

  const handleEditProduct = (product: Product) => {
    setEditingProduct(product);
    form.setFieldsValue(product);
    setModalVisible(true);
  };

  const handleDeleteProduct = async (product: Product) => {
    try {
      if (!product || !product.id) {
        throw new Error('Invalid product or product id');
      }
      const { error } = await supabase
        .from('products')
        .delete()
        .eq('id', product.id);

      if (error) throw error;

      message.success('Product deleted successfully');
      await fetchProducts();
    } catch (error) {
      console.error('Error deleting product:', error);
      message.error('Failed to delete product');
    }
  };

  const handleSaveProduct = async (values: Product) => {
    try {
      const productData = {
        ...values,
        tonnes_per_cube: values.tonnes_per_cube ? parseFloat(values.tonnes_per_cube.toString()) : null,
        price_per_tonne: values.price_per_tonne ? parseFloat(values.price_per_tonne.toString()) : null,
      };

      if (editingProduct) {
        if (!editingProduct.id) {
          throw new Error('Invalid product id for editing');
        }
        const { error } = await supabase
          .from('products')
          .update(productData)
          .eq('id', editingProduct.id);

        if (error) throw error;

        message.success('Product updated successfully');
      } else {
        const { error } = await supabase
          .from('products')
          .insert(productData);

        if (error) throw error;

        message.success('Product added successfully');
      }
      setModalVisible(false);
      form.resetFields();
      await fetchProducts();
    } catch (error) {
      console.error('Error saving product:', error);
      message.error('Failed to save product');
    }
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleTableChange = (pagination: any, filters: Record<string, any>, sorter: any) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };

  const filteredProducts = products.filter(product =>
    Object.values(product).some(val => 
      val && val.toString().toLowerCase().includes(searchValue.toLowerCase())
    )
  );

  const columns: ColumnType<Product>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      filters: [
        { text: 'Drainage', value: 'Drainage' },
        { text: 'Blue Metal', value: 'Blue Metal' },
        { text: 'Brown', value: 'Brown' },
      ],
      filteredValue: filteredInfo.type as string[] | null,
      onFilter: (value, record) => record.type === value,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      filters: [
        { text: 'RoadBase', value: 'RoadBase' },
        { text: 'Aggregate', value: 'Aggregate' },
        { text: 'Sand', value: 'Sand' },
        { text: 'Select Fill', value: 'Select Fill' },
      ],
      filteredValue: filteredInfo.category as string[] | null,
      onFilter: (value, record) => record.category === value,
    },
    {
      title: 'Product Code',
      dataIndex: 'product_code',
      key: 'product_code',
      sorter: (a, b) => (a.product_code || '').localeCompare(b.product_code || ''),
    },
    {
      title: 'Price/Tonne',
      dataIndex: 'price_per_tonne',
      key: 'price_per_tonne',
      render: (price: number | undefined) => price ? `$${price.toFixed(2)}` : 'N/A',
      sorter: (a, b) => (a.price_per_tonne || 0) - (b.price_per_tonne || 0),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Tooltip title="Edit Product">
            <Button 
              icon={<EditTwoTone twoToneColor="#52c41a" />} 
              onClick={() => handleEditProduct(record)} 
            />
          </Tooltip>
          <Tooltip title="Delete Product">
            <Button 
              icon={<DeleteTwoTone twoToneColor="#ff4d4f" />} 
              onClick={() => handleDeleteProduct(record)} 
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <div className="content-container">
      <div style={{ marginBottom: 16 }}>
        <Row justify="space-between" align="middle">
          <Col>
            <Title level={2}>Products</Title>
          </Col>
          <Col>
            <Space>
              <AutoComplete
                style={{ width: 200 }}
                placeholder="Search products"
                onSearch={handleSearch}
                onChange={(value) => setSearchValue(value)}
              />
              <Button onClick={clearAll}>Clear filters and sorters</Button>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleAddProduct}
              >
                Add Product
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
      <Table
        columns={columns}
        dataSource={filteredProducts}
        rowKey="id"
        loading={loading}
        onChange={handleTableChange}
        pagination={{ pageSize: 10 }}
      />
      <Modal
        title={editingProduct ? "Edit Product" : "Add Product"}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={800}
      >
        <Form 
          form={form} 
          onFinish={handleSaveProduct} 
          layout="vertical"
          initialValues={editingProduct || {}}
          className="product-form"
        >
          {editingProduct && (
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
          )}
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="name" label="Product Name" rules={[{ required: true, message: 'Please input the product name!' }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="type" label="Type" rules={[{ required: false, message: 'Please select the product type!' }]}>
                <Select
                  style={{ width: '100%' }}
                  dropdownMatchSelectWidth={false}
                >
                  <Option value="Drainage">Drainage</Option>
                  <Option value="Blue Metal">Blue Metal</Option>
                  <Option value="Brown">Brown</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="category" label="Category" rules={[{ required: true, message: 'Please select the product category!' }]}>
                <Select
                  style={{ width: '100%' }}
                  dropdownMatchSelectWidth={false}
                >
                  <Option value="RoadBase">RoadBase</Option>
                  <Option value="Aggregate">Aggregate</Option>
                  <Option value="Sand">Sand</Option>
                  <Option value="Select Fill">Select Fill</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="product_code" label="Product Code">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="lot_number" label="Lot Number">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="tonnes_per_cube" label="Tonnes per Cube">
                <InputNumber step="0.01" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="price_per_tonne" label="Price per Tonne">
                <InputNumber step="0.01" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="description" label="Description">
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingProduct ? 'Update Product' : 'Add Product'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Products;