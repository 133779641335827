import { createClient } from '@supabase/supabase-js'

const supabaseUrl = 'https://zphjnycudvdxxfcvfubo.supabase.co'
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InpwaGpueWN1ZHZkeHhmY3ZmdWJvIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTg0MTU4ODcsImV4cCI6MjAzMzk5MTg4N30.mv8qCb20szPzlYDKGXlooPvbtYZr2bH4HHOcnX3wZD4'

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
    auth: {
      persistSession: true,
      autoRefreshToken: true,
      detectSessionInUrl: false
    }
  })