import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null)
  const { user } = useAuth()

  useEffect(() => {
    setIsAuthenticated(!!user)
  }, [user])

  if (isAuthenticated === null) {
    return <div>Loading...</div>
  }

  return isAuthenticated ? <>{children}</> : <Navigate to='/login' replace />
}

export default ProtectedRoute