import 'default-passive-events';
import { BrowserRouter as Router } from 'react-router-dom';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { StyleProvider } from '@ant-design/cssinjs';
import { ConfigProvider } from 'antd';
import theme from './styles/theme'; // Import your theme
import 'default-passive-events';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <ConfigProvider theme={theme}>
      <StyleProvider hashPriority="high">
        <Router>
          <App />
        </Router>
      </StyleProvider>
    </ConfigProvider>
  </React.StrictMode>
);