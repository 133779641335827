import React, { useState, useEffect } from 'react';
import { Table, Input, Button, Space, message, Tooltip, Modal, Tag } from 'antd';
import { SearchOutlined, EditTwoTone, DeleteTwoTone, DollarTwoTone, EyeTwoTone } from '@ant-design/icons';
import { supabase } from '../../utils/supabaseClient';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import Credit from './Credit';
import JobDetailsDrawer from './JobDetailsDrawer';

interface DocketData {
  id: string;
  docket_number: number;
  job_number: number;
  customer_name: string;
  delivery_address: string;
  product: string;
  product_type: string | null;
  quantity: number;
  fleet_number: string;
  net_weight: number;
  is_credit: boolean;
  is_xbin: boolean;
}

const DocketList: React.FC = () => {
  const [data, setData] = useState<DocketData[]>([]);
  const [loading, setLoading] = useState(false);
  const [creditModalVisible, setCreditModalVisible] = useState(false);
  const [selectedDocketId, setSelectedDocketId] = useState<string | null>(null);
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    fetchDockets();
  }, []);

  const fetchDockets = async () => {
    setLoading(true);
    try {
      const { data: pulseDockets, error: pulseDocketsError } = await supabase
        .from('pulse_dockets')
        .select('*')
        .order('docket_number', { ascending: false });

      if (pulseDocketsError) throw pulseDocketsError;

      const { data: jobs, error: jobsError } = await supabase
        .from('jobs')
        .select('id, job_number');

      if (jobsError) throw jobsError;

      const { data: customers, error: customersError } = await supabase
        .from('customers')
        .select('id, customer_name');

      if (customersError) throw customersError;

      const { data: products, error: productsError } = await supabase
        .from('products')
        .select('id, name, type');

      if (productsError) throw productsError;

      const customersMap = new Map(customers.map(c => [c.id, c.customer_name]));
      const productsMap = new Map(products.map(p => [p.id, { name: p.name, type: p.type }]));
      const jobsMap = new Map(jobs.map(job => [job.id, job.job_number]));

      const formattedData: DocketData[] = (pulseDockets || []).map((docket) => {
        const product = productsMap.get(docket.product_id) || { name: 'N/A', type: null };
        return {
          id: docket.id,
          docket_number: docket.docket_number,
          job_number: jobsMap.get(docket.job_id) || 0,
          customer_name: customersMap.get(docket.customer_id) || 'N/A',
          delivery_address: docket.delivery_address,
          product: product.name,
          product_type: product.type,
          quantity: docket.quantity,
          fleet_number: docket.fleet_number,
          net_weight: docket.net_weight,
          is_credit: docket.is_credit,
          is_xbin: docket.is_xbin,
        };
      });

      setData(formattedData);
    } catch (error) {
      console.error('Error fetching dockets:', error);
      message.error('Failed to fetch dockets');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (
    selectedKeys: React.Key[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: keyof DocketData
  ) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex: keyof DocketData): ColumnType<DocketData> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()) ?? false,
  });

  const handleCredit = (record: DocketData) => {
    setSelectedDocketId(record.id);
    setCreditModalVisible(true);
  };

  const handleCreditCreated = () => {
    setCreditModalVisible(false);
    fetchDockets();
  };

  const handleEdit = (record: DocketData) => {
    console.log('Edit docket:', record.docket_number);
  };

  const handleDelete = (record: DocketData) => {
    console.log('Delete docket:', record.docket_number);
  };

  const handleViewDetails = (record: DocketData) => {
    setSelectedDocketId(record.id);
    setDrawerVisible(true);
  };

  const columns: ColumnsType<DocketData> = [
    {
      title: 'Docket Number',
      dataIndex: 'docket_number',
      key: 'docket_number',
      sorter: (a, b) => a.docket_number - b.docket_number,
      ...getColumnSearchProps('docket_number'),
    },
    {
      title: 'Job Number',
      dataIndex: 'job_number',
      key: 'job_number',
      sorter: (a, b) => a.job_number - b.job_number,
      ...getColumnSearchProps('job_number'),
    },
    {
      title: 'Customer',
      dataIndex: 'customer_name',
      key: 'customer_name',
      ...getColumnSearchProps('customer_name'),
    },
    {
      title: 'Delivery Address',
      dataIndex: 'delivery_address',
      key: 'delivery_address',
      ...getColumnSearchProps('delivery_address'),
    },
    {
      title: 'Product',
      key: 'product',
      render: (_, record) => `${record.product}${record.product_type ? ` (${record.product_type})` : ''}`,
      ...getColumnSearchProps('product'),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: 'Fleet Number',
      dataIndex: 'fleet_number',
      key: 'fleet_number',
      ...getColumnSearchProps('fleet_number'),
    },
    {
      title: 'Net Weight',
      dataIndex: 'net_weight',
      key: 'net_weight',
      sorter: (a, b) => a.net_weight - b.net_weight,
    },
    {
      title: 'Type',
      key: 'type',
      render: (_, record) => (
        <Tag color={record.is_credit ? 'red' : record.is_xbin ? 'blue' : 'green'}>
          {record.is_credit ? 'Credit' : record.is_xbin ? 'xBin' : 'Regular'}
        </Tag>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="small">
          <Tooltip title="View Details">
            <Button 
              icon={<EyeTwoTone twoToneColor="#1890ff" />} 
              onClick={() => handleViewDetails(record)}
            />
          </Tooltip>
          <Tooltip title="Credit">
            <Button 
              icon={<DollarTwoTone twoToneColor="#52c41a" />} 
              onClick={() => handleCredit(record)}
              disabled={record.is_credit}
            />
          </Tooltip>
          <Tooltip title="Edit">
            <Button icon={<EditTwoTone />} onClick={() => handleEdit(record)} />
          </Tooltip>
          <Tooltip title="Delete">
            <Button icon={<DeleteTwoTone twoToneColor="#ff4d4f" />} onClick={() => handleDelete(record)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        loading={loading}
        scroll={{ x: 'max-content' }}
      />
      <Modal
        title="Create Credit Docket"
        open={creditModalVisible}
        onCancel={() => setCreditModalVisible(false)}
        footer={null}
        width={600}
      >
        {selectedDocketId && (
          <Credit docketId={selectedDocketId} onCreditCreated={handleCreditCreated} />
        )}
      </Modal>
      <JobDetailsDrawer
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        jobId={selectedDocketId}
      />
    </>
  );
};

export default DocketList;