import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, Select, DatePicker, message, Typography, Popconfirm, Row, Col, Card, Upload, Space, Tooltip, AutoComplete, Tag, Collapse } from 'antd';
import { EditTwoTone, DeleteTwoTone, HistoryOutlined, PlusOutlined, UploadOutlined, CarTwoTone, UserOutlined, SearchOutlined } from '@ant-design/icons';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import type { FilterDropdownProps } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { supabase } from '../utils/supabaseClient';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import axios from 'axios';

dayjs.extend(customParseFormat);

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

interface Driver {
  id: string;
  name: string;
  fleet_number: string | null;
  phone_number: string;
  email: string;
  address: string;
  license_number: string;
  license_type: string;
  status: string | null;
  date_added: string;
  dob: string;
  emergency_contact_name: string;
  emergency_contact_number: string;
  notes: string;
  avatar: string;
}

interface Truck {
  id: string;
  fleet_number: string;
  type: string;
}

interface Assignment {
  id: string;
  driver_id: string;
  truck_id: string;
  assignment_date: string;
  end_date: string | null;
  truck: {
    fleet_number: string;
    type: string;
  };
}

const Drivers: React.FC = () => {
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [trucks, setTrucks] = useState<Truck[]>([]);
  const [availableTrucks, setAvailableTrucks] = useState<Truck[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [selectedDriverId, setSelectedDriverId] = useState<string | null>(null);
  const [assignments, setAssignments] = useState<Assignment[]>([]);
  const [form] = Form.useForm();
  const [editingDriver, setEditingDriver] = useState<Driver | null>(null);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [jobHistoryModalOpen, setJobHistoryModalOpen] = useState(false);
  const [jobHistory, setJobHistory] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs] | null>(null);
  const [filteredDrivers, setFilteredDrivers] = useState<Driver[]>([]);

  const getStatusColor = (status: string | null): string => {
    if (status === null) return 'default';
    
    switch (status.toLowerCase()) {
      case 'available':
        return 'green';
      case 'unavailable':
        return 'red';
      case 'annual leave':
        return 'orange';
      case 'wet day':
        return 'blue';
      case 'sick':
        return 'purple';
      default:
        return 'default';
    }
  };

  useEffect(() => {
    fetchDrivers();
    fetchTrucks();
  }, []);

  useEffect(() => {
    setFilteredDrivers(drivers);
  }, [drivers]);

  const fetchDrivers = async () => {
    setLoading(true);
    const { data, error } = await supabase
      .from('drivers')
      .select('*');
    if (error) {
      message.error('Failed to fetch drivers');
    } else {
      setDrivers(data || []);
    }
    setLoading(false);
  };

  const fetchTrucks = async () => {
    try {
      const { data: allTrucks, error: truckError } = await supabase
        .from('trucks')
        .select('id, fleet_number, type')
        .order('fleet_number');

      if (truckError) throw truckError;

      const { data: assignedTrucks, error: assignmentError } = await supabase
        .from('drivers')
        .select('fleet_number')
        .not('fleet_number', 'is', null);

      if (assignmentError) throw assignmentError;

      const assignedFleetNumbers = new Set(assignedTrucks.map(t => t.fleet_number));
      const availableTrucksData = allTrucks.filter(truck => !assignedFleetNumbers.has(truck.fleet_number));

      setTrucks(allTrucks || []);
      setAvailableTrucks(availableTrucksData || []);
    } catch (error) {
      console.error('Error fetching trucks:', error);
      message.error('Failed to fetch trucks');
    }
  };

  const handleSearch = (value: string) => {
    const filtered = drivers.filter(driver =>
      Object.values(driver).some(val => 
        val && val.toString().toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredDrivers(filtered);
  };

  const handleAddDriver = () => {
    setEditingDriver(null);
    form.resetFields();
    form.setFieldsValue({ date_added: dayjs().format('YYYY-MM-DD') });
    setModalOpen(true);
    setAvatarUrl(null);
  };

  const handleEditDriver = (driver: Driver) => {
    setEditingDriver(driver);
    form.setFieldsValue({
      ...driver,
      dob: driver.dob ? dayjs(driver.dob) : null,
      date_added: driver.date_added ? dayjs(driver.date_added) : null,
    });
    setModalOpen(true);
    setAvatarUrl(driver.avatar);
  };

  const handleDeleteDriver = async (id: string) => {
    try {
      const { error } = await supabase
        .from('drivers')
        .delete()
        .eq('id', id);
      if (error) throw error;
      message.success('Driver deleted successfully');
      fetchDrivers();
    } catch (error) {
      message.error('Failed to delete driver');
    }
  };

  const handleSaveDriver = async (values: any) => {
    try {
      const formattedValues = {
        ...values,
        dob: values.dob ? dayjs(values.dob).format('YYYY-MM-DD') : null,
        date_added: values.date_added ? dayjs(values.date_added).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
        avatar: avatarUrl,
      };

      if (editingDriver) {
        const { data, error } = await supabase
          .from('drivers')
          .update(formattedValues)
          .eq('id', editingDriver.id)
          .select()
          .single();

        if (error) throw error;
        if (data) {
          console.log('Updated driver:', data);
          if (data.fleet_number !== editingDriver.fleet_number) {
            await recordTruckAssignment(data.id, data.fleet_number);
          }
        }
        message.success('Driver updated successfully');
      } else {
        const { data, error } = await supabase
          .from('drivers')
          .insert(formattedValues)
          .select()
          .single();

        if (error) throw error;
        if (data) {
          console.log('Added new driver:', data);
          if (data.fleet_number) {
            await recordTruckAssignment(data.id, data.fleet_number);
          }
        }
        message.success('Driver added successfully');
      }
      setModalOpen(false);
      fetchDrivers();
    } catch (error) {
      console.error('Error saving driver:', error);
      message.error(`Failed to save driver: ${(error as any).message || 'Unknown error'}`);
    }
  };

  const recordTruckAssignment = async (driverId: string, fleetNumber: string | null) => {
    if (!fleetNumber) return;

    try {
      const truck = trucks.find(t => t.fleet_number === fleetNumber);
      if (!truck) {
        throw new Error('Truck not found');
      }

      const { error: endError } = await supabase
        .from('driver_truck_assignments')
        .update({ end_date: dayjs().format('YYYY-MM-DD HH:mm:ss') })
        .eq('driver_id', driverId)
        .is('end_date', null);

      if (endError) throw endError;

      const { error: insertError } = await supabase
        .from('driver_truck_assignments')
        .insert({
          driver_id: driverId,
          truck_id: truck.id,
          assignment_date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        });

      if (insertError) throw insertError;

    } catch (error) {
      console.error('Error recording truck assignment:', error);
      message.error('Failed to record truck assignment');
    }
  };
  const handleAvatarUpload = async (info: any) => {
    if (info.file.status === 'done') {
      try {
        const file = info.file.originFileObj;
        const formData = new FormData();
        formData.append('avatar', file);

        const response = await axios.post('http://localhost:5000/api/upload-avatar', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status !== 200) {
          throw new Error('Failed to upload avatar');
        }

        const { filePath } = response.data;
        setAvatarUrl(filePath);
        message.success('Avatar uploaded successfully');
      } catch (error) {
        console.error('Error uploading avatar:', error);
        message.error('Failed to upload avatar');
      }
    }
  };

  const showAssignmentHistory = async (driverId: string) => {
    setSelectedDriverId(driverId);
    try {
      const { data, error } = await supabase
        .from('driver_truck_assignments')
        .select(`
          id,
          driver_id,
          truck_id,
          assignment_date,
          end_date,
          truck:trucks (fleet_number, type)
        `)
        .eq('driver_id', driverId)
        .order('assignment_date', { ascending: false });

      if (error) throw error;

      if (data) {
        const processedData: Assignment[] = data.map(item => ({
          ...item,
          truck: Array.isArray(item.truck) ? item.truck[0] : item.truck
        }));
        setAssignments(processedData);
      } else {
        setAssignments([]);
      }
      setHistoryModalOpen(true);
    } catch (error) {
      console.error('Error fetching assignments:', error);
      message.error('Failed to fetch assignment history');
    }
  };

  const showJobHistory = async (driverId: string) => {
    setJobHistoryModalOpen(true);
    if (dateRange) {
      try {
        const [startDate, endDate] = dateRange;
        
        console.log('Date range:', startDate.format('YYYY-MM-DD'), 'to', endDate.format('YYYY-MM-DD'));
        
        const { data: driverAssignments, error: driverError } = await supabase
          .from('driver_truck_assignments')
          .select('truck_id, assignment_date, end_date')
          .eq('driver_id', driverId)
          .order('assignment_date', { ascending: false });
  
        if (driverError) throw driverError;
  
        console.log('Driver assignments:', driverAssignments);
  
        let allJobHistory: any[] = [];
        const uniqueJobIds = new Set();
        for (const assignment of driverAssignments) {
          const { data: jobAssignments, error: jobError } = await supabase
            .from('truck_job_assignments')
            .select(`
              id,
              assignment_date,
              truck:trucks(fleet_number),
              job:jobs(id, job_number, customer_id, delivery_address, product, quantity)
            `)
            .eq('truck_id', assignment.truck_id)
            .gte('assignment_date', startDate.format('YYYY-MM-DD'))
            .lte('assignment_date', endDate.format('YYYY-MM-DD'))
            .order('assignment_date', { ascending: false });
  
          if (jobError) throw jobError;
  
          console.log('Job assignments for truck:', assignment.truck_id, JSON.stringify(jobAssignments, null, 2));
  
          const uniqueJobs = jobAssignments?.filter(job => !uniqueJobIds.has(job.id)) || [];
          uniqueJobs.forEach(job => uniqueJobIds.add(job.id));
  
          const jobsWithUniqueKeys = uniqueJobs.map((job, index) => ({
            ...job,
            uniqueKey: `${job.id}-${index}`
          }));
  
          allJobHistory = [...allJobHistory, ...jobsWithUniqueKeys];
        }
  
        const customerIds = Array.from(new Set(allJobHistory.map(job => job.job.customer_id)));
        const { data: customers, error: customerError } = await supabase
          .from('customers')
          .select('id, customer_name')
          .in('id', customerIds);
  
        if (customerError) throw customerError;
  
        const customerMap = Object.fromEntries(customers.map(c => [c.id, c.customer_name]));
  
        const jobHistoryWithCustomers = allJobHistory.map(job => ({
          ...job,
          job: {
            ...job.job,
            customer_name: customerMap[job.job.customer_id] || 'N/A'
          }
        }));
  
        console.log('All job history:', JSON.stringify(jobHistoryWithCustomers, null, 2));
  
        setJobHistory(jobHistoryWithCustomers);
      } catch (error) {
        console.error('Error fetching job history:', error);
        message.error('Failed to fetch job history');
      }
    }
  };

  const getTruckColor = (type: string): string => {
    switch (type) {
      case 'Truck and Dog':
        return 'blue';
      case 'Body Truck':
        return 'green';
      case 'Semi Tipper':
        return 'orange';
      case '8 Wheeler':
        return 'purple';
      case '10 Wheeler':
        return 'red';
      default:
        return 'default';
    }
  };

  const getColumnSearchProps = (dataIndex: keyof Driver): ColumnType<Driver> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && clearFilters()} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()) ?? false,
  });

  const columns: ColumnsType<Driver> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      ...getColumnSearchProps('phone_number'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'License Number',
      dataIndex: 'license_number',
      key: 'license_number',
      ...getColumnSearchProps('license_number'),
    },
    {
      title: 'Assigned Truck',
      dataIndex: 'fleet_number',
      key: 'fleet_number',
      ...getColumnSearchProps('fleet_number'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string | null) => (
        <Tag color={getStatusColor(status)}>
          {status || 'N/A'}
        </Tag>
      ),
      filters: [
        { text: 'Available', value: 'Available' },
        { text: 'Unavailable', value: 'Unavailable' },
        { text: 'Annual Leave', value: 'Annual Leave' },
        { text: 'Wet Day', value: 'Wet Day' },
        { text: 'Sick', value: 'Sick' },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Tooltip title="Edit Driver">
            <Button icon={<EditTwoTone twoToneColor="#FFA000" />} 
              onClick={() => handleEditDriver(record)} 
            />
          </Tooltip>
          <Tooltip title="Delete Driver">
            <Popconfirm
              title="Are you sure you want to delete this driver?"
              onConfirm={() => handleDeleteDriver(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button icon={<DeleteTwoTone twoToneColor="#ff4d4f" />} />
            </Popconfirm>
          </Tooltip>
          <Tooltip title="View Truck Assignment History">
            <Button 
              icon={<CarTwoTone />} 
              onClick={() => showAssignmentHistory(record.id)} 
            />
          </Tooltip>
          <Tooltip title="View Job History">
            <Button 
              icon={<HistoryOutlined />} 
              onClick={() => {
                setSelectedDriverId(record.id);
                showJobHistory(record.id);
              }} 
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const assignmentColumns = [
    {
      title: 'Truck',
      dataIndex: ['truck', 'fleet_number'],
      key: 'fleet_number',
      render: (fleet_number: string, record: Assignment) => 
        `${record.truck?.fleet_number || 'N/A'} (${record.truck?.type || 'N/A'})`,
    },
    {
      title: 'Assigned Date',
      dataIndex: 'assignment_date',
      key: 'assignment_date',
      render: (date: string) => dayjs(date).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      render: (date: string | null) => date ? dayjs(date).format('DD/MM/YYYY HH:mm') : 'Current',
    },
  ];

  const licenseInfoItems = [
    {
      key: '1',
      label: 'License Information',
      children: (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="license_number" label="License Number">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="license_type" label="License Type">
              <Select>
                <Option value="MC">MC</Option>
                <Option value="HC">HC</Option>
                <Option value="HR">HR</Option>
                <Option value="MR">MR</Option>
                <Option value="LR">LR</Option>
                <Option value="C">C</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      ),
    },
  ];

  const emergencyContactItems = [
    {
      key: '1',
      label: 'Emergency Contact',
      children: (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="emergency_contact_name" label="Name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="emergency_contact_number" label="Phone Number">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <div className="content-container">
      <div style={{ marginBottom: 16 }}>
        <Row justify="space-between" align="middle">
          <Col>
            <Title level={2}>Drivers</Title>
          </Col>
          <Col>
            <Space>
              <AutoComplete
                style={{ width: 200 }}
                placeholder="Search drivers"
                onSearch={handleSearch}
                onChange={handleSearch}
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleAddDriver}
              >
                Add Driver
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
      <Table
        columns={columns}
        dataSource={filteredDrivers}
        rowKey="id"
        loading={loading}
      />
      <Modal
        title={editingDriver ? "Edit Driver" : "Add Driver"}
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        footer={null}
        width={800}
      >
        <Form form={form} onFinish={handleSaveDriver} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="fleet_number" label="Assigned Truck">
                <Select>
                  <Option value={null}>No Truck Assigned</Option>
                  {(editingDriver ? trucks : availableTrucks).map(truck => (
                    <Option key={truck.id} value={truck.fleet_number}>
                      <Tag color={getTruckColor(truck.type)}>{truck.fleet_number}</Tag>
                      {` (${truck.type})`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="status" label="Status">
                <Select>
                  <Option value="Available">Available</Option>
                  <Option value="Unavailable">Unavailable</Option>
                  <Option value="Annual Leave">Annual Leave</Option>
                  <Option value="Wet Day">Wet Day</Option>
                  <Option value="Sick">Sick</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Card title="Personal Information">
            <Row gutter={16}>
              <Col span={6}>
                {avatarUrl ? (
                  <div style={{ marginBottom: '10px' }}>
                    <img src={`http://localhost:5000${avatarUrl}`} alt="Avatar" style={{ width: '100%', marginBottom: '10px' }} />
                    <Space>
                      <Tooltip title="Delete Avatar">
                        <DeleteTwoTone 
                          twoToneColor="#ff4d4f" 
                          onClick={() => setAvatarUrl(null)}
                          style={{ fontSize: '18px', cursor: 'pointer' }}
                        />
                      </Tooltip>
                      <Tooltip title="Change Avatar">
                        <Upload
                          accept="image/*"
                          showUploadList={false}
                          customRequest={({ onSuccess }: any) => setTimeout(() => onSuccess('ok'), 0)}
                          onChange={handleAvatarUpload}
                        >
                          <EditTwoTone 
                            style={{ fontSize: '18px', cursor: 'pointer' }}
                          />
                        </Upload>
                      </Tooltip>
                    </Space>
                  </div>
                ) : (
                  <div style={{ marginBottom: '10px' }}>
                    <div style={{ 
                      width: '100%', 
                      paddingTop: '100%', 
                      background: '#f0f0f0', 
                      position: 'relative', 
                      marginBottom: '10px' 
                    }}>
                      <UserOutlined style={{ 
                        position: 'absolute', 
                        top: '50%', 
                        left: '50%', 
                        transform: 'translate(-50%, -50%)',
                        fontSize: '48px',
                        color: '#999'
                      }} />
                    </div>
                    <Upload
                      accept="image/*"
                      showUploadList={false}
                      customRequest={({ onSuccess }: any) => setTimeout(() => onSuccess('ok'), 0)}
                      onChange={handleAvatarUpload}
                    >
                      <Button icon={<UploadOutlined />}>Upload Avatar</Button>
                    </Upload>
                  </div>
                )}
              </Col>
              <Col span={18}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="phone_number" label="Phone Number" rules={[{ required: false }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="email" label="Email">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                  <Form.Item name="dob" label="Date of Birth">
                      <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name="address" label="Address">
                  <Input.TextArea />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Collapse items={licenseInfoItems} defaultActiveKey={[]} />

          <Collapse items={emergencyContactItems} defaultActiveKey={[]} style={{ marginTop: 16 }} />

          <Form.Item name="notes" label="Notes" style={{ marginTop: 16 }}>
            <Input.TextArea />
          </Form.Item>

          <Form.Item name="date_added" label="Date Added" hidden>
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingDriver ? 'Update Driver' : 'Add Driver'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Driver Assignment History"
        open={historyModalOpen}
        onCancel={() => setHistoryModalOpen(false)}
        footer={null}
        width={800}
      >
        <Table
          columns={assignmentColumns}
          dataSource={assignments}
          rowKey="id"
          loading={loading}
        />
      </Modal>

      <Modal
        title="Driver Job History"
        open={jobHistoryModalOpen}
        onCancel={() => setJobHistoryModalOpen(false)}
        footer={null}
        width={900}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <RangePicker 
            onChange={(dates) => setDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs])}
            format="DD/MM/YYYY"
            defaultValue={[dayjs().startOf('month'), dayjs()]}
          />
          <Button onClick={() => showJobHistory(selectedDriverId!)}>Fetch Job History</Button>
          <Table
            columns={[
              { 
                title: 'Date', 
                dataIndex: 'assignment_date', 
                key: 'date', 
                render: (date: string) => dayjs(date).format('DD/MM/YYYY')
              },
              { 
                title: 'Truck', 
                dataIndex: ['truck', 'fleet_number'], 
                key: 'truck',
              },
              { 
                title: 'Job#', 
                dataIndex: ['job', 'job_number'], 
                key: 'job_number',
              },
              { 
                title: 'Customer', 
                dataIndex: ['job', 'customer_name'], 
                key: 'customer',
              },
              { 
                title: 'Delivery Address', 
                dataIndex: ['job', 'delivery_address'], 
                key: 'address',
              },
              { 
                title: 'Product', 
                dataIndex: ['job', 'product'], 
                key: 'product',
              },
              { 
                title: 'Quantity', 
                dataIndex: ['job', 'quantity'], 
                key: 'quantity',
              },
            ]}
            dataSource={jobHistory}
            rowKey="uniqueKey"
          />
        </Space>
      </Modal>
    </div>
  );
};

export default Drivers;