import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ConfigProvider, message } from 'antd';
import './index.css';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import DailyPlanner from './components/DailyPlanner';
import Customers from './components/Customers';
import Products from './components/Products';
import Drivers from './components/Drivers';
import Trucks from './components/Trucks';
import Dockets from './components/Dockets';
import Layout from './components/Layout';
import UserProfile from './components/UserProfile';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';

const MessageProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <>
      {contextHolder}
      {React.Children.map(children, child =>
        React.isValidElement(child)
          ? React.cloneElement(child as React.ReactElement<any>, { messageApi })
          : child
      )}
    </>
  );
};

const App: React.FC = () => {
  return (
    <ConfigProvider>
      <MessageProvider>
        <AuthProvider>
          <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<ProtectedRoute><Layout /></ProtectedRoute>}>
                <Route index element={<Navigate to="/dashboard" replace />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="planner" element={<DailyPlanner />} />
                <Route path="customers" element={<Customers />} />
                <Route path="products" element={<Products />} />
                <Route path="drivers" element={<Drivers />} />
                <Route path="trucks" element={<Trucks />} />
                <Route path="dockets" element={<Dockets />} />
                <Route path="profile" element={<UserProfile />} />
              </Route>
              <Route path="*" element={<Navigate to="/dashboard" replace />} />
          </Routes>
        </AuthProvider>
      </MessageProvider>
    </ConfigProvider>
  );
};

export default App;